export * from './vidole-api.helpers'

interface MetaCriteria {
	__page?: number
	__limit?: number
}

export type Criteria<T> = Partial<Record<keyof T | keyof MetaCriteria, any>>

export function toApiQueryString<T>(criteria: Criteria<T>): string {
	const query = 'query=' + JSON.stringify(criteria)
	return encodeURI(query)
}
