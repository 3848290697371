import { LANGUAGE_OPTIONS } from '@/app/constants/constants'
import { ILanguage } from '@/app/models/auth.model'
import { Component, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Component({
	selector: 'app-language-bar',
	templateUrl: './language-bar.component.html',
	styleUrls: ['./language-bar.component.scss']
})
export class LanguageBarComponent implements OnInit {
	public currentLanguageId: string
	public languages = LANGUAGE_OPTIONS

	constructor(private translate: TranslateService) {
		this.translate.use(this.translate.getBrowserLang())
		this.currentLanguageId = this.translate.currentLang
	}

	ngOnInit(): void {}

	onSelectLanguage(language: ILanguage) {
		this.currentLanguageId = language.id
		this.translate.use(language.id)
	}

	openUrl(link: string) {
		window.open(link)
	}
}
