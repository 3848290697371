import { environment } from '../../environments/environment'
import { subscribeOncePipe } from '@/pipes/subscriptionPipe'
import { Injectable } from '@angular/core'
import { BehaviorSubject, combineLatest } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { USER_CHARACTER_PROPERTY_KEY } from '../constants/character-style.constant'
import {
	IAdventureBackground,
	IAdventurePreview,
	IAllAdventureContent
} from '../models/adventure.model'
import { ICharacter } from '../models/character.model'
import { ApiService } from './api.service'
import { CharacterService } from './character.service'

@Injectable({
	providedIn: 'root'
})
export class AdventureService {
	private endPoint = `${environment.endPointV1}/adventures`
	private _allAdventures: BehaviorSubject<IAllAdventureContent> =
		new BehaviorSubject<IAllAdventureContent>(null)
	private _mappedCharacterProperties: BehaviorSubject<IAdventurePreview[]> =
		new BehaviorSubject(null)
	constructor(
		private apiService: ApiService,
		private characterService: CharacterService
	) {
		this.setup()
	}

	setup() {
		// TODO: lazy load
		combineLatest([
			this.getAdventures().pipe(subscribeOncePipe()), // only once
			this.characterService.character.asObservable().pipe(debounceTime(500))
		]).subscribe(
			([allAdventureContent, characterData]: [IAllAdventureContent, ICharacter]) => {
				if (!allAdventureContent) return
				this._allAdventures.next(allAdventureContent)
				if (characterData && characterData.gender) {
					this.mapStoryPreview(characterData)
				}
			}
		)
	}

	getAdventures() {
		return this.apiService.get(`${this.endPoint}/preview-adventures`)
	}

	get adventures() {
		return this._allAdventures
	}

	public get mappedData() {
		return this._mappedCharacterProperties
	}
	public get mappedDataInstance() {
		return this._mappedCharacterProperties.getValue()
	}

	get currentAdventures() {
		return this._allAdventures.getValue()
	}

	private mapStoryPreview(currentCharacter: ICharacter) {
		if (!currentCharacter) return console.error(`${currentCharacter} is undefined!`)
		const { adventures, characterImages } = this._allAdventures.getValue() || {}
		if (!adventures) return

		const character: ICharacter = Object.values(USER_CHARACTER_PROPERTY_KEY).reduce(
			(acc: any, property: string) => {
				const propertyName =
					currentCharacter[property] && currentCharacter[property]['name']
				const propertyType = `${property}${property === 'hair' ? '-colored' : ''}-preview`
				if (!propertyName) return
				const characterProperty = characterImages.find(
					option =>
						option['name'] === propertyName &&
						option['gender'] === currentCharacter[property].gender &&
						option['type'] === propertyType
				)
				// if (characterProperty) this.preloadImage(characterProperty.image.secure_url)
				return { ...acc, [property]: characterProperty }
			},
			{}
		)
		const mappedData: IAdventurePreview[] = adventures.map(
			(adventure: IAdventureBackground) => {
				// this.preloadImage(adventure.backgroundPreview.secure_url)
				return {
					background: adventure,
					character
				}
			}
		)
		this._mappedCharacterProperties.next(mappedData)
	}
}
