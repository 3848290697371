import { Component, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Component({
	selector: 'app-cookie',
	templateUrl: './cookie.component.html',
	styleUrls: ['./cookie.component.scss']
})
export class CookieComponent implements OnInit {
	constructor(private translate: TranslateService) {}

	ngOnInit(): void {}
}
