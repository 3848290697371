import {
	Component,
	EventEmitter,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
	WritableSignal,
	signal
} from '@angular/core'
import { BooksService } from '@/app/services'
import { MatSidenav } from '@angular/material/sidenav'
import { BaseComponent } from '@/app/components/base.component'
import { PopupAuthenticationComponent } from '@/app/components/popup-authentication/popup-authentication.component'
import { VidoleSnackbarComponent } from '@/app/components/vidole-snackbar/vidole-snackbar.component'
import { BOOK_TYPE, SNACKBAR_TYPE } from '@/app/constants/constants'
import { IBook } from '@/app/models/book.model'
import { ICart } from '@/app/models/cart.model'
import { ICharacter } from '@/app/models/character.model'
import { IHero } from '@/app/models/hero.model'
import { AuthService, CartService } from '@/app/services'
import { BookService } from '@/app/services/book.service'
import { CharacterService } from '@/app/services/character.service'
import { FunctionService } from '@/app/services/function.service'
import { HeroService } from '@/app/services/hero.service'
import { PromotionService } from '@/app/services/promotion.service'
import { subscribeUntilPipe } from '@/pipes/subscriptionPipe'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Router } from '@angular/router'

@Component({
	selector: 'app-basket',
	templateUrl: './basket.component.html',
	styleUrls: ['./basket.component.scss']
})
export class BasketComponent extends BaseComponent implements OnInit, OnDestroy {
	@Output() toggleBasketSidenavEvent = new EventEmitter<string>()
	BOOK_TYPE = BOOK_TYPE
	quantity: number
	threeBooksDiscount = 0
	numbers = []
	cart: ICart
	totalPrice = 0
	cartForm: FormGroup
	isValidated = false
	onProcess = false

	public isCartEmpty = false
	public isMobileBrowser: WritableSignal<boolean> = signal(false)
	constructor(
		private booksService: BooksService,
		private bookService: BookService,
		private cartService: CartService,
		private authService: AuthService,
		private functionService: FunctionService,
		private promotionService: PromotionService,
		private snackbar: MatSnackBar,
		private heroService: HeroService,
		private characterService: CharacterService,
		private matDialog: MatDialog,
		private router: Router
	) {
		super()
	}
	get books() {
		const books = [...this.booksService.books$.getValue()]
		return books.slice(0, 3)
	}

	ngOnInit(): void {
		this.isMobileBrowser.set(this.functionService.isMobileBrowser)
		this.numbers = this.range(1, 1000)
		this.initForm()
		this.promotionService.resetDiscount();
		this.cart = this.cartService.getCart() as ICart;
		this.cartService.update$
			.pipe(subscribeUntilPipe(this.ngDestroyed$))
			.subscribe((cart: ICart) => {
				this.cart = cart;
				this.checkEmptyCart();
			})

		this.checkEmptyCart()
	}
	checkValue() {
		this.isValidated = !this.isValidated
	}

	range(start, end) {
		return Array.from({ length: end - start + 1 }, (_, idx) => start + idx)
	}

	increaseQuantity(book: IBook) {
		book.quantity++
		this.cartService.update(this.cartService.getCart())
	}

	decreaseQuantity(book: IBook) {
		book.quantity--
		this.cartService.update(this.cartService.getCart())
	}

	deleteItem(index: number) {
		this.cartService.removeBook(index)
		this.checkEmptyCart()
	}

	openSnackbar(message: string, type?: SNACKBAR_TYPE) {
		this.snackbar.openFromComponent(VidoleSnackbarComponent, {
			data: { message, type }
		})
	}

	addAnotherBook() {
		this.updateBook(null, null, null)
	}

	clickOrder() {
		if (!this.authService.getAuthorizationToken()) {
			return this.openAuthenticationPopup()
		}
		this.onProcess = true
		this.checkEmptyCart()
		this.router.navigate(['/', 'checkout'])
	}

	modifyBook(index: number) {
		const book = this.cart.books[index]
		this.updateBook(book.hero, book.character, book, index)
	}

	toggleBasketSidenav() {
		this.toggleBasketSidenavEvent.emit()
	}
	/**
	 * If edit ====> Assign hero to hero service, character to character service, book to book service
	 * 	and assign edit book index to edit index in book service
	 */
	private updateBook(
		hero: IHero | null,
		character: ICharacter | null,
		book: IBook | null,
		bookIndex?: number | null
	) {
		if (book?.id) {
			this.router.navigateByUrl('/customizer')
			return
		}

		this.heroService.setHero(hero)
		if (character === null) {
			this.characterService.resetCharacter()
		} else {
			this.characterService.setCharacter(character)
		}
		if (bookIndex == null) {

			this.bookService.clear()
		} else {
			this.bookService.setBook(book)
			this.bookService.setEditBookIndex(bookIndex)
		}

		if (book) {
			this.router.navigate(['/customizer'])
		} else {
			this.router.navigate(['/'])
		}
	}

	private initForm() {
		this.cartForm = new FormGroup({ confirm: new FormControl('', Validators.required) })
	}

	private checkEmptyCart() {
		if (this.cartService.getSize() === 0) {
			this.isCartEmpty = true
			this.bookService.clear()
			return
		}
		this.isCartEmpty = false
	}

	private openAuthenticationPopup() {
		this.matDialog.open(PopupAuthenticationComponent, {
			hasBackdrop: true,
			backdropClass: 'vidole-backdrop-deep'
		})
	}
}
