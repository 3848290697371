import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { newslatterSubscribtion } from '../models/newslatter-subscribtion.model'
import { Observable } from 'rxjs'

@Injectable({
	providedIn: 'root'
})
export class HubspotService {
	constructor(private http: HttpClient) {}
	submitNewslatterForm(newslatterSubscribtion: newslatterSubscribtion): Observable<any> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			// Authorization: `${environment.hubspotApikey}`,
			'X-Skip-Interceptor': 'true'
		})
		return this.http.post(
			`https://api.hsforms.com/submissions/v3/integration/submit/143494474/e065fa93-20bc-4390-b3ab-b21185b559ed`,
			newslatterSubscribtion,
			{ headers }
		)
	}
}
