import { environment } from '../../environments/environment'
import { APP_STORAGE } from '@/shared/storage/storage.inject'
import { Inject, Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { STORAGE_CONSTANTS } from '../constants/constants'
import { PaginationParams } from '../models/pagination.model'
import { IUpdateUser, IUser, IUserProperty } from '../models/user.model'
import { ApiService } from './api.service'
import { FunctionService } from './function.service'

@Injectable({ providedIn: 'root' })
export class UsersService {
	private userEndpoint = `${environment.endPointV1}/users`
	private _currentUser = new BehaviorSubject<IUser>(null)

	get currentUser$() {
		return this._currentUser.asObservable()
	}

	get currentUser() {
		return this._currentUser.getValue()
	}

	constructor(
		private apiService: ApiService,
		@Inject(APP_STORAGE) private appStorage: Storage,
		private functionService: FunctionService,
		private router: Router
	) {
		this._currentUser.next(this.loadSavedUser())
	}

	getUsers(pagination?: PaginationParams): Observable<IUser> {
		return this.apiService.get<IUser>(this.userEndpoint, pagination)
	}

	updateUser(_id: string, data: IUpdateUser) {
		return this.apiService.put<IUser>(`${this.userEndpoint}/${_id}`, data).pipe(
			map(user => {
				this.setCurrentUser(user)
				return user
			})
		)
	}

	deleteUser(_id: string) {
		return this.apiService.delete(`${this.userEndpoint}/${_id}`, { _id })
	}

	setCurrentUser(user: IUser) {
		this._currentUser.next(user)
		localStorage.setItem(STORAGE_CONSTANTS.CURRENT_USER, JSON.stringify(user))
	}

	setCurrentUserProperty(property: IUserProperty, value: string | boolean) {
		const updatedUser = { ...this.currentUser, [property]: value }
		this._currentUser.next(updatedUser)
	}

	signIn(token: string, user: IUser, refreshToken?: string) {
		this.setCurrentUser(user)
		const existedToken = this.appStorage.getItem(STORAGE_CONSTANTS.AUTH_TOKEN)
		if (existedToken) this.appStorage.removeItem(STORAGE_CONSTANTS.AUTH_TOKEN)

		const reToken = this.appStorage.getItem(STORAGE_CONSTANTS.REFRESH_TOKEN)
		if (reToken) this.appStorage.removeItem(STORAGE_CONSTANTS.REFRESH_TOKEN)

		this.appStorage.setItem(STORAGE_CONSTANTS.AUTH_TOKEN, token)
		this.appStorage.setItem(STORAGE_CONSTANTS.REFRESH_TOKEN, refreshToken)
	}

	signOut(refreshToken?: string) {
		this.appStorage.removeItem(STORAGE_CONSTANTS.AUTH_TOKEN)
		this.appStorage.removeItem(STORAGE_CONSTANTS.CURRENT_USER)
		this.setCurrentUser(null)
		this.router.navigate(['/'])
	}

	/**
	 * @return IUser with hashed password
	 */
	updateUserPwProperty(user: IUser, pw: /* real password */ string): IUser {
		const encryptedEntity = this.functionService.encryptEntity(pw)
		const hash = this.functionService.createHash(encryptedEntity)
		this.appStorage.setItem(STORAGE_CONSTANTS.PASSWORD_HASH, hash)
		return { ...user, password: encryptedEntity }
	}

	clearUserData() {
		const preserveKeys = [
			{
				key: STORAGE_CONSTANTS.COOKIES_ACCEPTED,
				value: this.appStorage.getItem(STORAGE_CONSTANTS.COOKIES_ACCEPTED)
			},
			{
				key: STORAGE_CONSTANTS.LANGUAGE,
				value: this.appStorage.getItem(STORAGE_CONSTANTS.LANGUAGE)
			}
		]
		this.appStorage.clear()
		this._currentUser.next(null)
		if (preserveKeys.length) {
			preserveKeys.forEach(k => k.value && this.appStorage.setItem(k.key, k.value))
		}
	}

	private loadSavedUser(): IUser {
		const userDataStr = localStorage.getItem(STORAGE_CONSTANTS.CURRENT_USER)
		if (!userDataStr) return null
		return JSON.parse(userDataStr)
	}
}
