export interface IMenuItem {
	title: string
	link: string
	external?: boolean
	hide?: boolean
}

export const menuItems: IMenuItem[] = [
	{
		title: 'nav.home',
		link: '/'
	},
	{
		title: 'nav.ourHistory',
		link: '/our-history'
	},
	{
		title: 'Nos livres',
		link: '/books'
	},
	{
		title: 'Nos livres personnalisables',
		link: '/books-custom'
	}
	// {
	// 	title: 'Contact',
	// 	external: true,
	// 	link: 'mailto:hello@leslivres-vidole.com'
	// },
	// {
	// 	title: 'Mon compte',
	// 	link: '/account'
	// 	// hide: environment.production
	// }
]
