// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// EXCLUDE ENV FILE: git rm -rf --cached ./src/environments/environment.ts

export const environment = {
	production: false,
	appId: 'VIDOLE_DEV',
	endPoint: 'https://dev.vidole-books.com',
    baseUrl: 'https://dev.vidole-books.com',
    baseEndPoint: 'https://dev.api.vidole-books.com',
    endPointV1: 'https://dev.api.vidole-books.com/v1',
	// stripePublicKey: 'pk_test_51HqwPtJXuwnciU9yyCdd5BHMARR7p5LZflE3ymNVCBhQyBKLgctw6WpR7BajOPJiUcA3H8OIvEa3Am93A4gAdhGI00CCExM8Cf',
	stripePublicKey:
		'pk_test_51OqYtAFc8DTwwa00JYPNJR2LsiabRyspQQGNgvYdu5rVN9VJtwG7Jx3Fe4x7O7lcDNOGp4nVl9tV0Ve7Jv0nMldg00zvyK9AAa',
	paypalClientID:
		'AclAuQVrFvMF2dz0FTFlaJos5uuGoeseKwEQtwSVaI__GhHd3vlrEd0cThwI_6w_y0ryOvuXMdehF12M',
	bookMakerBaseUrl: 'https://dev.maker.vidole-books.com/api/public'
}
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
