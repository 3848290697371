import { STORAGE_CONSTANTS } from '@/app/constants/constants'
import { CookieComponent } from '@/app/pages/legals/cookie/cookie.component'
import { FunctionService } from '@/app/services/function.service'
import { APP_STORAGE } from '@/shared/storage/storage.inject'
import {
	Component,
	HostListener,
	Inject,
	OnInit,
	WritableSignal,
	signal
} from '@angular/core'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

declare let $: any

export interface CookiePopupModalData {
	cookiesAccepted: boolean
}

@Component({
	selector: 'app-cookie-popup-modal',
	templateUrl: './cookie-popup-modal.component.html',
	styleUrls: ['./cookie-popup-modal.component.scss']
})
export class CookiePopupModalComponent implements OnInit {
	public cookiesAccepted = false
	private isBrowser: WritableSignal<boolean> = signal(false)

	@HostListener('window:resize', ['$event'])
	resizeEvent(event?: any) {
		if (window.innerHeight > 800) return
		this.dialogRef.addPanelClass('height-small')
		console.log(window.innerWidth, window.innerHeight)
	}

	constructor(
		private dialogRef: MatDialogRef<CookiePopupModalComponent>,
		@Inject(MAT_DIALOG_DATA) data: CookiePopupModalData,
		@Inject(APP_STORAGE) private storage: Storage,
		private readonly functionService: FunctionService,
		private matDialog: MatDialog
	) {
		this.dialogRef.disableClose = true
		this.dialogRef.addPanelClass('cookie-modal-pannel')
		this.cookiesAccepted = data.cookiesAccepted || false
	}

	ngOnInit(): void {
		this.isBrowser.set(this.functionService.isPlatformBrowser)
		this.resizeEvent(null)
	}

	onCheck() {
		this.cookiesAccepted = !this.cookiesAccepted
		document.getElementById('accept-cookie')['checked'] = this.cookiesAccepted
	}

	setCookiesAccepted(): void {
		this.storage.setItem(STORAGE_CONSTANTS.COOKIES_ACCEPTED, new Date().toString())
		if (this.isBrowser())
			localStorage.setItem(STORAGE_CONSTANTS.COOKIES_ACCEPTED, new Date().toString())
	}

	closeModal(): void {
		this.setCookiesAccepted()
		this.dialogRef.close(true)
	}

	linkCookiePage() {
		this.matDialog.open(CookieComponent, { maxHeight: '90vh' })
		this.functionService.scrollToPosition([0, 0])
	}
}
