import { Injectable } from '@angular/core'

import { CookieService } from 'ngx-cookie-service'

@Injectable()
export class BrowserStorage implements Storage {
	[index: number]: string
	[key: string]: any
	length: number

	// tslint:disable-next-line: variable-name
	constructor(private _cookieService: CookieService) {}

	public clear(): void {
		this._cookieService.deleteAll('/')
	}

	public getItem(key: string): string | null {
		return this._cookieService.get(key)
	}

	public key(index: number): string | null {
		return this.cookieService.getAll().propertyIsEnumerable[index]
	}

	public removeItem(key: string): void {
		return this._cookieService.delete(key, '/')
	}

	public setItem(key: string, data: string): void {
		return this._cookieService.set(key, data, undefined, '/')
	}
}
