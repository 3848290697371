import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { LayoutComponent } from './layout/layout.component'

const routes: Routes = [
	{
		path: '',
		component: LayoutComponent,
		loadChildren: () => import('@/app/pages/pages.module').then(m => m.PagesModule)
	}
]

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			anchorScrolling: 'enabled',
			scrollPositionRestoration: 'top'
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
