import {
	BookPreviewModalComponent,
	IBookPreviewModalData
} from '@/app/components/book-preview-modal/book-preview-modal.component'
import { BOOK_TYPE } from '@/app/constants/constants'
import { Book } from '@/app/models/book-customizer.interfaces'
import { BooksService, CartService } from '@/app/services'
import { Component, Input, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'

@Component({
	selector: 'app-mini-book-card',
	templateUrl: './mini-book-card.component.html',
	styleUrls: ['./mini-book-card.component.scss']
})
export class MiniBookCardComponent implements OnInit {
	BOOK_TYPE = BOOK_TYPE
	@Input() book: Book
	@Input() fullwidth = false

	get alreadyAddedToCart(): boolean {
		return (
			this.cartService.getCart().books.findIndex(_book => {
				return _book.bookRef === this.book.id
			}) > -1
		)
	}

	constructor(
		private booksService: BooksService,
		private cartService: CartService,
		private router: Router,
		private dialog: MatDialog
	) {}

	ngOnInit(): void {
		if (!this.book) {
			throw new Error('Please provide book input')
		}
	}

	customize() {
		this.booksService.selectedBook$.next(this.book)
		this.router.navigateByUrl('/customizer')
	}

	addToCart() {
		this.cartService.addBook({
			name: this.book.title,
			type: this.book.type,
			quantity: 1,
			bookRef: this.book.id,
			unitPrice: this.book.price
		})
	}

	preview() {
		this.dialog.open(BookPreviewModalComponent, {
			hasBackdrop: true,
			disableClose: false,
			data: {
				book: this.book
			} as IBookPreviewModalData,
			panelClass: 'fullscreen-sm',
			backdropClass: 'vidole-backdrop-deep'
		})
	}
}
