import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { ClientFeedBackInput } from '../models/feedback.interface'
import { PaginationParams } from '../models/pagination.model'

@Injectable({ providedIn: 'root' })
export class ApiService {
	constructor(private http: HttpClient) {}

	public getParams(option?: PaginationParams) {
		if (!option) return {}
		const { query, limit, skip, count, sort, populate, fields } = option
		const params = {
			...(limit ? { limit: limit && limit.toString() } : {}),
			...(skip ? { skip: (skip && skip.toString()) || '0' } : {}),
			...(count ? { count: `${count}` || 'false' } : {}),
			...(sort
				? {
						sort: sort
							? typeof sort === 'object'
								? JSON.stringify(sort)
								: sort
							: undefined
				  }
				: {}),
			...(query
				? {
						query: query
							? typeof query === 'object'
								? JSON.stringify(query)
								: query
							: undefined
				  }
				: {}),
			...(populate
				? {
						populate: populate
							? typeof populate === 'object'
								? JSON.stringify(populate)
								: populate
							: undefined
				  }
				: {}),
			...(fields
				? {
						fields: fields
							? typeof fields === 'object'
								? JSON.stringify(fields)
								: fields
							: undefined
				  }
				: {})
		}

		for (const key in params) {
			if (params.hasOwnProperty(key)) {
				if (typeof params[key] === 'undefined') delete params[key]
			}
		}
		return params
	}

	public get<T>(endPoint: string, pagination?: PaginationParams) {
		return this.http.get<T>(endPoint, {
			params: this.getParams(pagination)
		})
	}

	public post<T>(
		endPoint: string,
		data: any,
		options?: {
			headers?:
				| HttpHeaders
				| {
						[header: string]: string | string[]
				  }
			observe?: 'body'
			params?:
				| HttpParams
				| {
						[param: string]: string | string[]
				  }
			reportProgress?: boolean
			responseType?: 'json'
			withCredentials?: boolean
		}
	) {
		return this.http.post<T>(endPoint, data, options)
	}

	public put<T>(
		endPoint: string,
		body: any,
		options?: {
			headers?:
				| HttpHeaders
				| {
						[header: string]: string | string[]
				  }
			observe?: 'body'
			params?:
				| HttpParams
				| {
						[param: string]: string | string[]
				  }
			reportProgress?: boolean
			responseType?: 'json'
			withCredentials?: boolean
		}
	) {
		return this.http.put<T>(endPoint, body, options)
	}

	public delete<T>(endPoint: string, params: { [key: string]: any }) {
		return this.http.delete<T>(endPoint, { params })
	}
}
