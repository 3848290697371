import { Component, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

@Component({
	selector: 'app-sale-legal',
	templateUrl: './sale-legal.component.html',
	styleUrls: ['./sale-legal.component.scss']
})
export class SaleLegalComponent implements OnInit {
	constructor(private translate: TranslateService) {}

	ngOnInit(): void {}
}
