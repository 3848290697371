import { environment } from '../../environments/environment'
import { Injectable } from '@angular/core'
import { ApiService } from './api.service'

@Injectable({
	providedIn: 'root'
})
export class StoryWriterService {
	private endPoint = `${environment.baseEndPoint}/send-mail`
	constructor(private apiService: ApiService) {}

	sendStory(title: string, story: string) {
		return this.apiService.post(this.endPoint, { title, body: story })
	}
}
