import { environment } from '../../environments/environment'
import { APP_STORAGE } from '@/shared/storage/storage.inject'
import { Inject, Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { STORAGE_CONSTANTS } from '../constants/constants'
import { IHero } from '../models/hero.model'
import { IPagination } from '../models/pagination.model'
import { ApiService } from './api.service'

@Injectable({ providedIn: 'root' })
export class HeroService {
	private endPoint = `${environment.endPointV1}/heroes`;
	private _initialHero: any = {
		firstname: "",
		lastname: "",
		gender: 'boy' 
	}

	private _hero: BehaviorSubject<IHero>
	constructor(
		@Inject(APP_STORAGE) private appStorage: Storage,
		private apiService: ApiService
	) {
		this.initHeroInfo(this._initialHero)
	}

	private initHeroInfo(hero: IHero) {
		const savedInfo = localStorage.getItem(STORAGE_CONSTANTS.PRESAVED_HERO)
		if (savedInfo && savedInfo !== 'undefined') {
			const parsedInfo = JSON.parse(savedInfo) as IHero
			if (parsedInfo) hero = parsedInfo
		}

		this._hero = new BehaviorSubject(hero)
	}

	public resetHero(){
		this._hero.next(this._initialHero);
	}

	public createHero(hero: IHero) {
		return this.apiService.post<IHero>(this.endPoint, { ...hero })
	}

	public updateHero(hero: IHero) {
		const { _id } = hero
		if (!_id) return
		return this.apiService.post<IHero>(this.endPoint, { ...hero }, { params: { _id } })
	}

	public getUserHeroes() {
		return this.apiService.get<IPagination<IHero>>(`${this.endPoint}/heroes`)
	}

	public deleteHero(_id: string) {
		return this.apiService.delete<{ ok?: boolean }>(this.endPoint, { _id })
	}

	public get hero() {
		return this._hero
	}

	public get infoExisted() {
		return this._hero && !!this._hero.getValue()
	}

	public get currentHero() {
		return this._hero.getValue()
	}

	public setHero(heroInfo: IHero) {
		this._hero.next(heroInfo)
		this.preSaveHero()
	}
	public setHeroProperty(propertyName: string, value: string) {
		this._hero.next({ ...this.currentHero, [propertyName]: value })
		this.preSaveHero()
	}
	private preSaveHero() {
		if (!this.currentHero) return
		const currentHero = JSON.stringify(this.currentHero)
		// const saved = this.appStorage.getItem(STORAGE_CONSTANTS.PRESAVED_HERO)
		// if (saved) this.appStorage.removeItem(STORAGE_CONSTANTS.PRESAVED_HERO)
		localStorage.setItem(STORAGE_CONSTANTS.PRESAVED_HERO, currentHero)
	}
}
