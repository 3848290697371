export const emailRegExp = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+\s*$/

export const telRegex = /^\+{0,1}[0-9]{7,15}\s*$/

export const WEIGHT_PER_BOOK = 0.6 // kg

export enum BOOK_PRICE {
	PERSONALIZE = 35
}

export enum BOOK_TYPE {
	STANDART = 'STANDART',
	CUSTOMIZABLE = 'CUSTOMIZABLE'
}

export enum STORAGE_CONSTANTS {
	HERO_CUSTOMIZER_VISITED_STEPS = 'hero-customizer-visited-steps',
	_HERO_CUSTOMIZER_VISITED_STEPS = '__HERO_CUSTOMIZER_VISITED_STEPS',
	HERO_CUSTOMIZER_CURRENT_STEP = 'HERO_CUSTOMIZER_CURRENT_STEP',
	_HERO_CUSTOMIZER_CURRENT_STEP = '_HERO_CUSTOMIZER_CURRENT_STEP',
	COOKIES_ACCEPTED = 'cookies-accepted',
	PRESAVED_CHARACTER = 'presaved-character',
	PRESAVED_HERO = 'presaved-hero',
	LANGUAGE = 'language',
	AUTH_TOKEN = 'jwt-auth-token',
	REFRESH_TOKEN = 'refresh-token',
	CURRENT_USER = 'CURRENT_USER',
	USER_LOGGED_IN = 'user-logged-in',
	CART = 'cart',
	PASSWORD = 'password',
	PASSWORD_HASH = 'password-hash',
	DEV_USER_AUTHENTICATED = 'dev-user-authenticated',
	BOOK = 'book',
	EDIT_BOOK_INDEX = 'edit-book-index',
	USER_PROMOTION = 'user-promotion',
	DELIVERY_OPTION = 'delivery-option',
	PAID_CART = 'paid-cart',
	RESET_PASSWORD_TOKEN = 'RESET_PASSWORD_TOKEN',
	JWY_PAYLOAD = 'JWY_PAYLOAD',
	BOOK_INPUTS = 'BOOK_INPUTS',
	SELECTED_BOOK = 'SELECTED_BOOK',
	DEFAULT_BOOK_INPUTS = 'DEFAULT_BOOK_INPUTS',
	SELECTED_CHAPTERS = 'SELECTED_CHAPTERS',
	CUSTOMIZER_BOOK = 'CUSTOMIZER_BOOK',
	AFFILIATE_REF = 'AFFILIATE_REF'
}

export enum SNACKBAR_TYPE {
	SUCCESS = 'success',
	INFO = 'info',
	ERROR = 'error'
}

export enum SLIDER_OPTIONS {
	SELECT = 'select',
	LEFT = 'left',
	RIGHT = 'right'
}

export enum LANGUAGE_CONSTANTS {
	ENGLISH = 'en',
	FRENCH = 'fr'
}

export const LANGUAGE_OPTIONS = {
	ENGLISH: {
		id: 'en',
		title: 'EN'
	},
	FRENCH: {
		id: 'fr',
		title: 'FR'
	}
}

export const VIDOLE_FACEBOOK_URL = 'https://www.facebook.com/leslivresvidole'
export const VIDOLE_INSTAGRAM_URL = 'https://www.instagram.com/leslivresvidole'

export const COUNTRIES = [
	{ code: 'fr', fr: 'France Métropolitaine', belong: 'fr', en: 'France' },
	{ code: 'ch', fr: 'Suisse', en: 'Switzerland' },
	{ code: 'be', fr: 'Belgique', en: 'Belgium' },

	{ code: 'af', fr: 'Afghanistan', en: 'Afghanistan' },
	{ code: 'za', fr: 'Afrique du Sud', en: 'South Africa' },
	{ code: 'al', fr: 'Albanie', en: 'Albania' },
	{ code: 'dz', fr: 'Algérie', en: 'Algeria' },
	{ code: 'de', fr: 'Allemagne', en: 'Germany' },
	{
		code: 'busin',
		fr: 'Allemagne - Busingen',
		pays: 'ALLEMAGNE',
		en: 'Germany - Busingen'
	},
	{
		code: 'helgo',
		fr: 'Allemagne - Helgoland',
		pays: 'ALLEMAGNE',
		en: 'Germany - Helgoland'
	},
	{ code: 'ad', fr: 'Andorre', unavailable: true, en: 'Andorra' },
	{ code: 'ao', fr: 'Angola', en: 'Angola' },
	{ code: 'aq', fr: 'Antarctique', unavailable: true, en: 'Antarctica' },
	{ code: 'sa', fr: 'Arabie saoudite', en: 'Saudi Arabia' },
	{ code: 'ar', fr: 'Argentine', en: 'Argentina' },
	{ code: 'am', fr: 'Arménie', en: 'Armenia' },
	{ code: 'ac', fr: 'Ascension', en: 'Ascension' },
	{ code: 'au', fr: 'Australie', en: 'Australia' },
	{
		code: 'cx',
		fr: 'Australie - Île Christmas',
		pays: 'AUSTRALIE',
		en: 'Christmas Island'
	},
	{
		code: 'cc',
		fr: 'Australie - Îles Cocos',
		pays: 'AUSTRALIE',
		en: 'Cocos (Keeling) Islands'
	},
	{ code: 'at', fr: 'Autriche', en: 'Austria' },
	{ code: 'az', fr: 'Azerbaïdjan', en: 'Azerbaijan' },
	{ code: 'bh', fr: 'Bahreïn', en: 'Bahrain' },
	{ code: 'bd', fr: 'Bangladesh', en: 'Bangladesh' },
	{ code: 'bj', fr: 'Bénin', pays: 'BENIN', en: 'Benin' },
	{ code: 'by', fr: 'Biélorussie', pays: 'BELARUS', en: 'Belarus' },
	{ code: 'bo', fr: 'Bolivie', en: 'Bolivia' },
	{
		code: 'ba',
		fr: 'Bosnie-Herzégovine',
		pays: 'BOSNIE HERZÉGOVINE',
		en: 'Bosnia and Herzegovina'
	},
	{ code: 'br', fr: 'Brésil', en: 'Brazil' },
	{ code: 'bn', fr: 'Brunei', pays: 'BRUNÉI DARUSSALAM', en: 'Brunei Darussalam' },
	{ code: 'bg', fr: 'Bulgarie', en: 'Bulgaria' },
	{ code: 'bf', fr: 'Burkina Faso', en: 'Burkina Faso' },
	{ code: 'bi', fr: 'Burundi', en: 'Burundi' },
	{ code: 'kh', fr: 'Cambodge', en: 'Cambodia' },
	{ code: 'cm', fr: 'Cameroun', en: 'Cameroon' },
	{ code: 'ca', fr: 'Canada', en: 'Canada' },
	{ code: 'cl', fr: 'Chili', en: 'Chile' },
	{ code: 'cn', fr: 'Chine', en: 'China' },
	{ code: 'cy', fr: 'Chypre', en: 'Cyprus' },
	{ code: 'co', fr: 'Colombie', en: 'Colombia' },
	{ code: 'km', fr: 'Comores', en: 'Comoros' },
	{
		code: 'kp',
		fr: 'Corée du Nord',
		pays: 'RÉP DÉM DE CORÉE (CORÉE DU NORD)',
		en: 'North Korea'
	},
	{
		code: 'kr',
		fr: 'Corée du Sud',
		pays: 'RÉP DE CORÉE (CORÉE DU SUD)',
		en: 'South Korea'
	},
	{ code: 'cr', fr: 'Costa Rica', pays: 'COSTA-RICA', en: 'Costa Rica' },
	{ code: 'ci', fr: `Côte d'Ivoire`, en: `Cote D'Ivoire` },
	{ code: 'hr', fr: 'Croatie', en: 'Croatia' },
	{ code: 'cw', fr: 'Curaçao', unavailable: true, en: 'Curaçao' },
	{ code: 'dk', fr: 'Danemark', en: 'Denmark' },
	{ code: 'fo', fr: 'Danemark-Îles Féroé', pays: 'DANEMARK', en: 'Faroe Islands' },
	{ code: 'dj', fr: 'Djibouti', en: 'Djibouti' },
	{ code: 'eg', fr: 'Égypte', pays: 'EGYPTE', en: 'Egypt' },
	{
		code: 'ae',
		fr: 'Émirats arabes unis',
		pays: 'EMIRATS ARABES UNIS',
		en: 'United Arab Emirates'
	},
	{ code: 'er', fr: 'Érythrée', pays: 'ERYTHRÉE', en: 'Eritrea' },
	{ code: 'es', fr: 'Espagne', en: 'Spain' },
	{ code: 'ea1', fr: 'Espagne - Ceuta', pays: 'ESPAGNE', en: 'Spain - Ceuta' },
	{
		code: 'ic',
		fr: 'Espagne - Îles Canaries',
		pays: 'ESPAGNE',
		en: 'Spain - Canary Islands'
	},
	{ code: 'ea2', fr: 'Espagne - Melilla', pays: 'ESPAGNE', en: 'Spain - Melilla' },
	{ code: 'ee', fr: 'Estonie', en: 'Estonia' },
	{ code: 'us', fr: 'Etats-Unis', en: 'United States' },
	{ code: 'et', fr: 'Éthiopie', pays: 'ETHIOPIE', en: 'Ethiopia' },
	{ code: 'fj', fr: 'Fidji', en: 'Fiji' },
	{ code: 'fi', fr: 'Finlande', en: 'Finland' },
	{ code: 'ax', fr: 'Finlande - Aland', pays: 'FINLANDE', en: 'Finlande - Aland' },
	{ code: 'ga', fr: 'Gabon', en: 'Gabon' },
	{ code: 'ge', fr: 'Géorgie', en: 'Georgia' },
	{
		code: 'gs',
		fr: 'Géorgie Du Sud - Iles Sandwich Du Sud',
		pays: 'GÉORGIE DU SUD',
		en: 'South Georgia and the South Sandwich Islands'
	},
	{ code: 'gh', fr: 'Ghana', en: 'Ghana' },
	{ code: 'gi', fr: 'Gibraltar', en: 'Gibraltar' },
	{ code: 'gr', fr: 'Grèce', en: 'Greece' },
	{ code: 'mont', fr: 'Grèce - Mont Athos', pays: 'GRÈCE', en: 'Grèce - Mont Athos' },
	{ code: 'gl', fr: 'Groenland', en: 'Greenland' },
	{ code: 'gu', fr: 'Guam', en: 'Guam' },
	{ code: 'gt', fr: 'Guatemala', en: 'Guatemala' },
	{ code: 'gn', fr: 'Guinée', pays: 'RÉP. DE GUINÉE', en: 'Guinea' },
	{
		code: 'gq',
		fr: 'Guinée équatoriale',
		pays: 'GUINÉE EQUAT.',
		en: 'Equatorial Guinea'
	},
	{ code: 'gf', fr: 'Guyane', pays: 'GUYANA', en: 'French Guiana' },
	{ code: 'gp', fr: 'Guadeloupe', belong: 'fr', en: 'Guadeloupe' },
	{ code: 'hn', fr: 'Honduras', en: 'Honduras' },
	{ code: 'hk', fr: 'Hong Kong', pays: 'HONGKONG', en: 'Hong Kong' },
	{ code: 'hu', fr: 'Hongrie', en: 'Hungary' },
	{ code: 'nf', fr: 'Île Norfolk', pays: 'NORFOLK', en: 'Norfolk Island' },
	{ code: 'ck', fr: 'Îles Cook', pays: 'ILES COOK', en: 'Cook Islands' },
	{
		code: 'fk',
		fr: 'Îles Malouines',
		pays: 'FALKLAND',
		en: 'Falkland Islands (Malvinas)'
	},
	{
		code: 'mp',
		fr: 'Îles Mariannes du Nord',
		pays: 'ILES MARIANNES',
		en: 'Northern Mariana Islands'
	},
	{ code: 'pn', fr: 'Îles Pitcairn', pays: 'PITCAIRN', en: 'Pitcairn' },
	{
		code: 'vg',
		fr: 'Îles Vierges britanniques',
		pays: 'ILES VIERGES (GB)',
		en: 'Virgin Islands, British'
	},
	{
		code: 'vi',
		fr: 'Îles Vierges des États-Unis',
		pays: 'ILES VIERGES (GB)',
		en: 'Virgin Islands, U.S.'
	},
	{ code: 'in', fr: 'Inde', en: 'India' },
	{ code: 'id', fr: 'Indonésie', en: 'Indonesia' },
	{ code: 'iq', fr: 'Irak', pays: 'IRAQ', en: 'Iraq' },
	{ code: 'ir', fr: 'Iran', en: 'Iran, Islamic Republic Of' },
	{ code: 'ie', fr: 'Irlande', en: 'Ireland' },
	{ code: 'is', fr: 'Islande', en: 'Iceland' },
	{ code: 'il', fr: 'Israël', en: 'Israel' },
	{ code: 'it', fr: 'Italie', en: 'Italy' },
	{
		code: 'camp',
		fr: `Italie - Camppione d'Italia`,
		pays: 'ITALIE',
		en: `Italie - Camppione d'Italia`
	},
	{ code: 'livi', fr: 'Italie - Livigno', pays: 'ITALIE', en: 'Italie - Livigno' },
	{ code: 'jp', fr: 'Japon', en: 'Japan' },
	{ code: 'jo', fr: 'Jordanie', en: 'Jordan' },
	{ code: 'kz', fr: 'Kazakhstan', en: 'Kazakhstan' },
	{ code: 'ke', fr: 'Kenya', en: 'Kenya' },
	{ code: 'kg', fr: 'Kirghizistan', en: 'Kyrgyzstan' },
	{ code: 'ki', fr: 'Kiribati', en: 'Kiribati' },
	{ code: 'kw', fr: 'Koweït', pays: 'KUWAIT', en: 'Kuwait' },
	{ code: 're', fr: 'La Réunion', belong: 'fr', en: 'Reunion' },
	{ code: 'ls', fr: 'Lesotho', en: 'Lesotho' },
	{ code: 'lv', fr: 'Lettonie', en: 'Latvia' },
	{ code: 'lb', fr: 'Liban', en: 'Lebanon' },
	{ code: 'li', fr: 'Liechtenstein', en: 'Liechtenstein' },
	{ code: 'lt', fr: 'Lituanie', en: 'Lithuania' },
	{ code: 'lu', fr: 'Luxembourg', en: 'Luxembourg' },
	{ code: 'mo', fr: 'Macao', en: 'Macao' },
	{ code: 'mk', fr: 'Macédoine', en: 'Macedonia, The Former Yugoslav Republic of' },
	{ code: 'my', fr: 'Malaisie', en: 'Malaysia' },
	{ code: 'mv', fr: 'Maldives', en: 'Maldives' },
	{ code: 'ml', fr: 'Mali', en: 'Mali' },
	{ code: 'mt', fr: 'Malte', en: 'Malta' },
	{ code: 'ma', fr: 'Maroc', en: 'Morocco' },
	{ code: 'mh', fr: 'Marshall', pays: 'ILES MARSHALL', en: 'Marshall Islands' },
	{ code: 'mq', fr: 'Martinique', belong: 'fr', en: 'Martinique' },
	{ code: 'yt', fr: 'Mayotte', belong: 'fr', en: 'Mayotte' },
	{ code: 'mx', fr: 'Mexique', en: 'Mexico' },
	{
		code: 'fm',
		fr: 'Micronésie',
		unavailable: true,
		en: 'Micronesia, Federated States of'
	},
	{ code: 'md', fr: 'Moldavie', pays: 'MOLDOVA', en: 'Moldova, Republic of' },
	{ code: 'mc', fr: 'Monaco', unavailable: true, en: 'Monaco' },
	{ code: 'me', fr: 'Monténégro', unavailable: true, en: 'Monténégro' },
	{ code: 'na', fr: 'Namibie', en: 'Namibia' },
	{ code: 'nr', fr: 'Nauru', en: 'Nauru' },
	{ code: 'np', fr: 'Népal', en: 'Nepal' },
	{ code: 'ne', fr: 'Niger', en: 'Niger' },
	{ code: 'ng', fr: 'Nigeria', pays: 'NIGÉRIA', en: 'Nigeria' },
	{ code: 'no', fr: 'Norvège', en: 'Norway' },
	{ code: 'nc', fr: 'Nouvelle-Calédonie', belong: 'fr', en: 'New Caledonia' },
	{ code: 'nz', fr: 'Nouvelle-Zélande', en: 'New Zealand' },
	{ code: 'om', fr: 'Oman', en: 'Oman' },
	{ code: 'ug', fr: 'Ouganda', en: 'Uganda' },
	{ code: 'pk', fr: 'Pakistan', en: 'Pakistan' },
	{ code: 'pw', fr: 'Palaos', unavailable: true, en: 'Palau' },
	{ code: 'pa', fr: 'Panama', en: 'Panama' },
	{
		code: 'pg',
		fr: 'Papouasie-Nouvelle-Guinée',
		pays: 'PAPOUASIE ET NOUVELLE-GUINÉE',
		en: 'Papua New Guinea'
	},
	{ code: 'nl', fr: 'Pays-Bas', en: 'Netherlands' },
	{ code: 'ph', fr: 'Philippines', en: 'Philippines' },
	{ code: 'pl', fr: 'Pologne', en: 'Poland' },
	{ code: 'pf', fr: 'Polynésie française', belong: 'fr', en: 'French Polynesia' },
	{ code: 'pt', fr: 'Portugal', en: 'Portugal' },
	{ code: 'qa', fr: 'Qatar', en: 'Qatar' },
	{
		code: 'cf',
		fr: 'République centrafricaine',
		unavailable: true,
		en: 'Central African Republic'
	},
	{
		code: 'cd',
		fr: 'République démocratique du Congo',
		pays: 'RÉP. DÉM DU CONGO',
		en: 'Congo, The Democratic Republic of the'
	},
	{ code: 'cg', fr: 'République du Congo', pays: 'RÉP DU CONGO', en: 'Congo' },
	{ code: 'cz', fr: 'République Tchéque', pays: 'REP. TCHÈQUE', en: 'Czech Republic' },
	{ code: 'ro', fr: 'Roumanie', en: 'Romania' },
	{ code: 'gb', fr: 'Royaume-Uni', pays: 'GRANDE-BRETAGNE', en: 'United Kingdom' },
	{ code: 'gg', fr: 'Royaume-Uni - Guernesey', belong: 'uk', en: 'Guernsey' },
	{ code: 'im', fr: 'Royaume-Uni - Île de Man', belong: 'uk', en: 'Isle of Man' },
	{ code: 'je', fr: 'Royaume-Uni - Jersey', belong: 'uk', en: 'Jersey' },
	{
		code: 'io',
		fr: 'Royaume-Uni - Océan Indien',
		belong: 'uk',
		en: 'British Indian Ocean Territory'
	},
	{ code: 'ru', fr: 'Russie', en: 'Russian Federation' },
	{ code: 'rw', fr: 'Rwanda', en: 'RWANDA' },
	{ code: 'bl', fr: 'Saint-Barthélemy', belong: 'fr', en: 'Saint-Barthélemy' },
	{ code: 'sh', fr: 'Sainte-Hélène', en: 'Saint Helena' },
	{ code: 'sm', fr: 'Saint-Marin', en: 'San Marino' },
	{ code: 'mf', fr: 'Saint-Martin', belong: 'fr', en: 'Saint-Martin' },
	{
		code: 'pm',
		fr: 'Saint-Pierre-et-Miquelon',
		unavailable: true,
		en: 'Saint Pierre and Miquelon'
	},
	{
		code: 'vc',
		fr: 'Saint-Vincent-et-les-Grenadines',
		pays: 'SAINT-VINCENT ET GRENADINES',
		en: 'Saint Vincent and the Grenadines'
	},
	{ code: 'sb', fr: 'Salomon', pays: 'ILES SALOMON', en: 'Solomon Islands' },
	{ code: 'sv', fr: 'Salvador', pays: 'EL SALVADOR', en: 'El Salvador' },
	{ code: 'ws', fr: 'Samoa', en: 'Samoa' },
	{ code: 'as', fr: 'Samoa américaines', belong: 'us', en: 'American Samoa' },
	{
		code: 'st',
		fr: 'Sao Tomé-et-Principe',
		pays: 'SAO TOME ET PRINCIPE',
		en: 'Sao Tome and Principe'
	},
	{ code: 's1', fr: 'Secteurs postaux', unavailable: true, en: 'Secteurs postaux' },
	{ code: 'sn', fr: 'Sénégal', en: 'Senegal' },
	{ code: 'rs', fr: 'Serbie', en: 'Serbie' },
	{ code: 'sg', fr: 'Singapour', en: 'Singapore' },
	{ code: 'sk', fr: 'Slovaquie', en: 'Slovakia' },
	{ code: 'si', fr: 'Slovénie', en: 'Slovenia' },
	{ code: 'sd', fr: 'Soudan', en: 'Sudan' },
	{ code: 'lk', fr: 'Sri Lanka', en: 'Sri Lanka' },
	{ code: 'se', fr: 'Suède', en: 'Sweden' },
	{ code: 'sr', fr: 'Suriname', en: 'Suriname' },
	{ code: 'sz', fr: 'Swaziland', en: 'Swaziland' },
	{ code: 'tj', fr: 'Tadjikistan', en: 'Tajikistan' },
	{ code: 'tw', fr: 'Taïwan', en: 'Taiwan, Province of China' },
	{ code: 'tz', fr: 'Tanzanie', en: 'Tanzania, United Republic of' },
	{ code: 'td', fr: 'Tchad', en: 'Chad' },
	{
		code: 'tf',
		fr: 'Terres australes françaises',
		belong: 'fr',
		en: 'French Southern Territories'
	},
	{ code: 'th', fr: 'Thaïlande', en: 'Thailand' },
	{ code: 'tl', fr: 'Timor oriental', en: 'Timor-Leste' },
	{ code: 'tg', fr: 'Togo', en: 'Togo' },
	{ code: 'to', fr: 'Tonga', en: 'Tonga' },
	{
		code: 'tt',
		fr: 'Trinité-et-Tobago',
		pays: 'TRINITÉ ET TOBAGO',
		en: 'Trinidad and Tobago'
	},
	{ code: 'ta', fr: 'Tristan Da Cuhna', belong: 'uk', en: 'Tristan Da Cuhna' },
	{ code: 'tn', fr: 'Tunisie', en: 'Tunisia' },
	{ code: 'tm', fr: 'Turkménistan', en: 'Turkmenistan' },
	{ code: 'tr', fr: 'Turquie', en: 'Turkey' },
	{ code: 'tv', fr: 'Tuvalu', en: 'Tuvalu' },
	{ code: 'ua', fr: 'Ukraine', en: 'Ukraine' },
	{ code: 'vu', fr: 'Vanuatu', en: 'Vanuatu' },
	{ code: 'va', fr: 'Vatican', en: 'Holy See (Vatican City State)' },
	{ code: 'vn', fr: 'Viêt Nam', pays: 'VIETNAM', en: 'Viet Nam' },
	{ code: 'wf', fr: 'Wallis-et-Futuna', belong: 'fr', en: 'Wallis and Futuna' },
	{ code: 'zm', fr: 'Zambie', en: 'Zambia' },
	{ code: 'zw', fr: 'Zimbabwe', en: 'Zimbabwe' },
	// more
	{ code: 'CORSE', fr: 'CORSE', en: 'CORSE' },
	{ code: 'AÇORES', fr: 'AÇORES', en: 'AÇORES' },
	{ code: 'ÎLES BALÉARES', fr: 'ÎLES BALÉARES', en: 'ÎLES BALÉARES' },
	{ code: 'ÎLES FÉROS', fr: 'ÎLES FÉROS', en: 'ÎLES FÉROS' },
	{ code: 'ANTIGUA ET BARBUDA', fr: 'ANTIGUA ET BARBUDA', en: 'ANTIGUA ET BARBUDA' },
	{ code: 'ARUBA', fr: 'ARUBA', en: 'ARUBA' },
	{ code: 'ÎLES BAHAMA', fr: 'ÎLES BAHAMA', en: 'ÎLES BAHAMA' },
	{ code: 'BARBADE', fr: 'BARBADE', en: 'BARBADE' },
	{ code: 'BELIZE', fr: 'BELIZE', en: 'BELIZE' },
	{ code: 'BERMUDA', fr: 'BERMUDA', en: 'BERMUDA' },
	{ code: 'BHOUTAN', fr: 'BHOUTAN', en: 'BHOUTAN' },
	{ code: 'BONAIRE', fr: 'BONAIRE', en: 'BONAIRE' },
	{ code: 'BOTSWANA', fr: 'BOTSWANA', en: 'BOTSWANA' },
	{ code: 'ÎLES CAÏMANS', fr: 'ÎLES CAÏMANS', en: 'ÎLES CAÏMANS' },
	{ code: 'CUBA', fr: 'CUBA', en: 'CUBA' },
	{ code: 'CURACAO', fr: 'CURACAO', en: 'CURACAO' },
	{ code: 'DOMINACA', fr: 'DOMINACA', en: 'DOMINACA' },
	{
		code: 'RÉPUBLIQUE DOMINICAINE',
		fr: 'RÉPUBLIQUE DOMINICAINE',
		en: 'RÉPUBLIQUE DOMINICAINE'
	},
	{ code: 'GAMBIE', fr: 'GAMBIE', en: 'GAMBIE' },
	{ code: 'GRENADE', fr: 'GRENADE', en: 'GRENADE' },
	{ code: 'HAÏTI', fr: 'HAÏTI', en: 'HAÏTI' },
	{ code: 'JAMAÏQUE', fr: 'JAMAÏQUE', en: 'JAMAÏQUE' },
	{ code: 'YÉMEN', fr: 'YÉMEN', en: 'YÉMEN' },
	{ code: 'CAP-VERT', fr: 'CAP-VERT', en: 'CAP-VERT' },
	{ code: 'MALAWI', fr: 'MALAWI', en: 'MALAWI' },
	{ code: 'MAURITANIE', fr: 'MAURITANIE', en: 'MAURITANIE' },
	{ code: 'ILE MAURICE', fr: 'ILE MAURICE', en: 'ILE MAURICE' },
	{ code: 'MONGOLIE', fr: 'MONGOLIE', en: 'MONGOLIE' },
	{ code: 'MONTSERRAT', fr: 'MONTSERRAT', en: 'MONTSERRAT' },
	{ code: 'MOZAMBIQUE', fr: 'MOZAMBIQUE', en: 'MOZAMBIQUE' },
	{ code: 'MYANMAR', fr: 'MYANMAR', en: 'MYANMAR' },
	{ code: 'NICARAGUA', fr: 'NICARAGUA', en: 'NICARAGUA' },
	{ code: 'PARAGUAY', fr: 'PARAGUAY', en: 'PARAGUAY' },
	{ code: 'PEROU', fr: 'PEROU', en: 'PEROU' },
	{ code: 'PUERTO RICO', fr: 'PUERTO RICO', en: 'PUERTO RICO' },
	{ code: 'SEYCHELLES', fr: 'SEYCHELLES', en: 'SEYCHELLES' },
	{ code: 'SIERRA LEONE', fr: 'SIERRA LEONE', en: 'SIERRA LEONE' },
	{ code: 'SOMALIE', fr: 'SOMALIE', en: 'SOMALIE' },
	{ code: 'ST. KITTS ET NEVIS', fr: 'ST. KITTS ET NEVIS', en: 'ST. KITTS ET NEVIS' },
	{ code: 'ST. LUCIA', fr: 'ST. LUCIA', en: 'ST. LUCIA' },
	{
		code: 'SAINT EUSTATIUS ET SABA',
		fr: 'SAINT EUSTATIUS ET SABA',
		en: 'SAINT EUSTATIUS ET SABA'
	},
	{ code: 'SYRIE', fr: 'SYRIE', en: 'SYRIE' }
]

export type CONTENT_PREVIEW_EVENT_TYPE = 'open' | 'select' | 'deselect'
