import { environment } from '../../environments/environment'
import { PolarBehaviorSubject } from '@/shared/helper-classes/polar-behavior-subject'
import { Injectable, NgZone } from '@angular/core'
import { loadStripe, Stripe } from '@stripe/stripe-js'

@Injectable({ providedIn: 'root' })
export class StripeService {
	constructor(protected zone: NgZone) {
		;(async () => {
			const self = this.getInstance()
			self._stripe = await loadStripe(environment.stripePublicKey)
			self._onLoad.next(true)
		})()
	}

	public static get stripe(): Stripe {
		return this._stripe
	}

	public get stripe() {
		const instance = this.getInstance()
		return instance._stripe
	}

	public static get onLoad() {
		return this.onLoad
	}

	public get onLoad() {
		const self = this.getInstance()
		return self._onLoad
	}

	private static _stripe: Stripe

	private static instance: typeof StripeService = StripeService

	private static _onLoad: PolarBehaviorSubject<boolean> =
		new PolarBehaviorSubject<boolean>(false)

	private getInstance() {
		const { instance } = StripeService
		return instance === (this as any) ? StripeService : instance
	}
}
