import { environment } from '../../environments/environment'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import {
	IPromotionResponse,
	IPromotionRestrictionsArgs,
	PromotionType
} from '../models/promotion.model'
import { ApiService } from './api.service'
import { CartService } from './cart.service'

@Injectable({
	providedIn: 'root'
})
export class PromotionService {
	private endPoint = `${environment.endPointV1}/promotions`

	constructor(private apiService: ApiService, private cartService: CartService) {}

	public checkPromotionCode(code: string): Observable<IPromotionResponse> {
		return this.apiService.post(`${this.endPoint}/check-promotion-valid/${code}`, {
			countryCode: this.cartService.deliveryAddressFormValue$.value?.country?.code
		} as IPromotionRestrictionsArgs)
	}

	public removeCodePromotion(type: PromotionType) {
		this.cartService.update({ [type]: null })
	}

	public resetDiscount() {
		this.cartService.update({ promotionCode: null, shippingCode: null })
	}
}
