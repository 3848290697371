import { Injectable } from '@angular/core'
import { environment } from '../../environments/environment'
import { ApiService } from './api.service'
import { SubscribeEmailResponse } from '../models/subscribe-email.interface'

@Injectable()
export class SubscribeEmailService {
	private endPoint = `${environment.endPointV1}/subscribe-email`
	constructor(private apiService: ApiService) {}

	subscribeEmail(email: string) {
		return this.apiService.post<SubscribeEmailResponse>(this.endPoint, { email })
	}

	getSubscribedEmail() {
		return this.apiService.get<SubscribeEmailResponse[]>(this.endPoint)
	}
}
