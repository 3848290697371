import { Injectable, OnDestroy } from '@angular/core'
import { Subject, Subscription } from 'rxjs'

@Injectable()
export abstract class BaseComponent implements OnDestroy {
	public _subscription = new Subscription()
	public loading: {
		[key: string]: boolean
	} = {}
	public ngDestroyed$ = new Subject<void>()
	public ngOnDestroy() {
		this._subscription.unsubscribe()
		this.ngDestroyed$.next()
		this.ngDestroyed$.unsubscribe()
	}
	public trackByIndex(index: number, item: any) {
		return index
	}
	public trackById(index: number, item: any) {
		return item._id || item.id
	}
}
