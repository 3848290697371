import { environment } from '../../environments/environment'
import { subscribeOncePipe } from '@/pipes/subscriptionPipe'
import { PolarBehaviorSubject } from '@/shared/helper-classes/polar-behavior-subject'
import { Injectable } from '@angular/core'
import { cloneDeep } from 'lodash-es'
import {
	CHARACTER_API_DISPLAY_PROPERTY_KEY,
	CHARACTER_API_PROPERTY_KEY,
	CHARACTER_PROPERTY_MAP,
	EYE_COLOR_ORDER,
	SKIN_COLOR_ORDER
} from '../constants/character-style.constant'
import {
	IAllCharacterProperties,
	ICharacterImage,
	ICharacterImageDisplayData,
	ICharacterProperties,
	ICharacterPropertyKey
} from '../models/character-image.model'
import { ApiService } from './api.service'
import { FunctionService } from './function.service'

@Injectable({
	providedIn: 'root'
})
export class CharacterImageService {
	private endPoint = `${environment.endPointV1}/character-images`

	public processLoading: PolarBehaviorSubject<boolean> = new PolarBehaviorSubject(false)

	public characterImages: PolarBehaviorSubject<IAllCharacterProperties> =
		new PolarBehaviorSubject(null)

	public currentCharacterPropertiesByGender: PolarBehaviorSubject<ICharacterProperties> =
		new PolarBehaviorSubject(null)

	public currentCharacterDisplayData: PolarBehaviorSubject<ICharacterImageDisplayData> =
		new PolarBehaviorSubject(null)
	public precondition = {
		isAlbinism: false,
		gender: null
	}
	constructor(private apiService: ApiService, private functionService: FunctionService) {
		this.getAllImages()
	}

	public getAllImages() {
		this.processLoading.next(false)
		return this.apiService
			.get<IAllCharacterProperties>(`${this.endPoint}/get-default`)
			.pipe(subscribeOncePipe())
			.subscribe((data: IAllCharacterProperties) => {
				if (!data) return
				this.sortCharacterProperties(data)
			})
	}

	public sortCharacterProperties(data: IAllCharacterProperties) {
		if (!data) return
		const clonedObject = cloneDeep<IAllCharacterProperties>(data)
		const { boy, girl } = clonedObject
		const orderArrays = Object.keys(CHARACTER_PROPERTY_MAP)
		;[boy, girl].map((genderObj: ICharacterProperties) => {
			const objKeys: ICharacterPropertyKey[] = Object.keys(
				genderObj
			) as ICharacterPropertyKey[]
			objKeys.map((key: ICharacterPropertyKey) => {
				if (orderArrays.includes(key)) {
					const newPropertyArray: ICharacterImage[] = CHARACTER_PROPERTY_MAP[key].map(
						(k: string) => {
							return genderObj[key].find((obj: ICharacterImage) => obj.name === k)
						}
					)
					genderObj[key] = newPropertyArray
				}
			})
		})
		this.characterImages.next(clonedObject)
		this.processLoading.next(false)
	}

	public generateCharacterProperties(
		gender: 'boy' | 'girl',
		allCharacterImages: IAllCharacterProperties,
		isAlbinism = false
	) {
		this.precondition = { gender, isAlbinism }
		this.processLoading.next(true)
		const selectedCharacterGender =
			allCharacterImages[(gender === 'boy' && 'boy') || 'girl']
		this.currentCharacterPropertiesByGender.next(selectedCharacterGender)

		const skinColors = SKIN_COLOR_ORDER.map(skinColor => {
			const skins: Array<any> = selectedCharacterGender[CHARACTER_API_PROPERTY_KEY.SKIN]
			const data = skins.find(skin => skin.name === skinColor.name)
			return {
				display: {
					...data,
					displayColor: skinColor.color
				},
				data
			}
		})

		const eyeColors = EYE_COLOR_ORDER.map(
			(option: /* color */ string, index: number) => ({
				display: {
					...selectedCharacterGender['eyes'][index],
					displayColor: option
				},
				data: {
					...selectedCharacterGender['eyes'][index]
				}
			})
		)

		const hairStyles = selectedCharacterGender[
			CHARACTER_API_PROPERTY_KEY.HAIR_COLORED
		].map((data, i) => ({
			display:
				selectedCharacterGender[CHARACTER_API_DISPLAY_PROPERTY_KEY.HAIR_DISPLAY][i],
			data
		}))

		const accessories = selectedCharacterGender['accessories'].map((data, index) => ({
			display:
				selectedCharacterGender[CHARACTER_API_DISPLAY_PROPERTY_KEY.ACCESSORIES_DISPLAY][
					index
				],
			data
		}))

		const outfits = selectedCharacterGender['outfit'].map((data, i) => ({
			display:
				selectedCharacterGender[CHARACTER_API_DISPLAY_PROPERTY_KEY.OUTFIT_DISPLAY][i],
			data
		}))

		this.currentCharacterDisplayData.next({
			skinColors,
			eyeColors,
			hairStyles,
			accessories,
			outfits
		})
		this.processLoading.next(false)
	}

	public get selectedCharacterGenderProperties() {
		return this.currentCharacterPropertiesByGender.getValue()
	}

	public get selectedCharacterData() {
		return this.currentCharacterDisplayData.getValue()
	}
}
