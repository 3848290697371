import { ErrorStateMatcher } from '@angular/material/core'
import { FormGroupDirective, NgForm, FormControl } from '@angular/forms'

export class CustomErrorStateMatcher extends ErrorStateMatcher {
	isErrorState(
		control: FormControl | null,
		form: FormGroupDirective | NgForm | null
	): boolean {
		return !!(control && control.invalid && (control.dirty || control.touched))
	}
}

export class OnSubmitErrorStateMatcher extends ErrorStateMatcher {
	isErrorState(
		control: FormControl | null,
		form: FormGroupDirective | NgForm | null
	): boolean {
		const isSubmitted = form && form.submitted
		return !!(
			control &&
			control.invalid &&
			(control.dirty || control.touched || control.pristine) &&
			isSubmitted
		)
	}
}
