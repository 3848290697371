import { environment } from '../../environments/environment'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { STORAGE_CONSTANTS } from '../constants/constants'
import { Criteria, toApiQueryString } from '../helpers'
import { ApiResponse } from '../models/api.interfaces'
import { Book, Chapter } from '../models/book-customizer.interfaces'
import { getFromLocalStorage } from '../utils'

@Injectable({
	providedIn: 'root'
})
export class BooksService {
	books$ = new BehaviorSubject<Book[]>([])
	selectedBook$ = new BehaviorSubject<null | Book>(null)
	selectedBookChapters$ = new BehaviorSubject<Chapter[]>([])

	constructor(private http: HttpClient) {
		this.initialize()
	}

	getBooks(criteria?: Criteria<Book>) {
		return this.http
			.get<ApiResponse<Book[]>>(
				`${environment.bookMakerBaseUrl}/books${
					criteria ? '?' + toApiQueryString(criteria) : ''
				}`
			)
			.pipe(
				map(response => {
					this.books$.next(response.data)
					return response.data
				}),
				catchError((err: Error) => {
					console.error(err)
					return of([])
				})
			)
	}

	initialize() {
		const selectedBook = getFromLocalStorage(STORAGE_CONSTANTS.SELECTED_BOOK)
		this.selectedBook$.next(selectedBook)

		this.selectedBook$.subscribe(data => {
			localStorage.setItem(STORAGE_CONSTANTS.SELECTED_BOOK, JSON.stringify(data))
		})
	}
}
