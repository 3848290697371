import { environment } from '../../environments/environment'
import { EventEmitter, Inject, Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { get } from 'lodash-es'
import { BehaviorSubject, Observable } from 'rxjs'
import { BOOK_TYPE, SNACKBAR_TYPE, STORAGE_CONSTANTS } from '../constants/constants'
import { IAddress } from '../models/address.model'
import { IBook } from '../models/book.model'
import { ICart, ICreateCartResponse } from '../models/cart.model'
import { ApiService } from './api.service'
import { BookCoverService } from './book-cover.service'
import { BookService } from './book.service'
import { CharacterService } from './character.service'
import { HeroService } from './hero.service'
import { NotificationService } from './notification.service'

@Injectable({ providedIn: 'root' })
export class CartService {
	update$ = new EventEmitter<ICart>()
	deliveryAddressFormValue$ = new BehaviorSubject<Partial<IAddress>>({})

	private endPoint = `${environment.endPointV1}/carts`;
	private publicEndPoint = `${environment.endPointV1}`

	private cart: ICart

	constructor(
		private router: Router,
		private apiService: ApiService,
		private bookCoverService: BookCoverService,
		private alertService: NotificationService,
		private bookService: BookService,
		private heroService: HeroService,
		private characterService: CharacterService,
	) {
		this.initCart()
		this.deliveryAddressFormValue$.next(this.cart.deliveryAddress || {})
	}

	getCart() {
		return this.cart
	}

	getSize() {
		return this.cart.books.length
	}

	update(cart: Partial<ICart>) {
		console.log("update cart");
		Object.assign(this.cart, cart)
		this.saveCart()
	}

	addBook(book: IBook) {
		const savedBook = this.cart.books[this.bookService.currentBookIndex]
		if (savedBook) {
			Object.assign(savedBook, book)
		} else {
			book.index = this.cart.books.length
			this.cart.books.push(book)
		}
		this.saveCart()
	}

	removeBook(index: number) {
		this.cart.books.splice(index, 1)
		this.saveCart()
	}

	// Only for tome 1 book
	checkSelectedAdventures() {
		this.cart.books.forEach((book, index) => {
			if (!book.adventures) return
			if (book.adventures.length !== 10) {
				this.bookService.setBook(book)
				this.bookService.setEditBookIndex(index)
				this.router.navigateByUrl('/adventure')
			}
		})
	}

	createCart(): Observable<ICreateCartResponse> {
		console.log("create cart");
		try{
			const currentBooKCoverInstance = this.bookCoverService.bookCoverInstance ?  this.bookCoverService.bookCoverInstance[0] : {id: 0};
			const books = this.cart.books.map(book => {
				console.log(book, "book");
				const _book: any = {
					...book
				}
				console.log("1111");
				if (book.adventures) {
					_book.adventures = book.adventures.map(adventure => adventure._id || adventure.chapter)
				}
				console.log(_book.adventures);
				console.log("2");

				// if (book.character) {
				// 	_book.character = {
				// 		...Object.keys(book.character).reduce(
				// 			(acc, curr) => ({ ...acc, [curr]: get(book, `character.${curr}.id`) }),
				// 			{}
				// 		),
				// 		personalizable: book.character.personalizable
				// 	}
				// }
				console.log(_book.character);

				// Only for tome 1 book
				if (!book.type && book.adventures) {
					book.type = BOOK_TYPE.CUSTOMIZABLE
				}

				// If is not a book from book maker app
				// if (!book.bookRef) {
				// 	_book.coverId = currentBooKCoverInstance.id
				// }

				return _book
			})
			this.checkSelectedAdventures();			
			console.log("end create cart");
			return this.apiService.post<ICreateCartResponse>(this.endPoint, {
				...this.cart,
				affiliateRef: localStorage.getItem(STORAGE_CONSTANTS.AFFILIATE_REF),
				books
			} as ICart)
		}
		catch(error:any){
			console.log(error, ">>> error");
		}
	}

	runAfterSuccessPayment(billingCode?: string) {
		if (billingCode) this.update({ billingCode })
		this.update({ paid: true })
		this.bookService.clear()
		this.characterService.resetCharacter()
		this.heroService.setHero(null)
		this.router.navigate(['/', 'invoice-preview'])
	}

	getUnitBookPrice(): Observable<number> {
		return this.apiService.get<number>(`${this.endPoint}/unit-book-price`)
	}

	resetCart() {
		localStorage.removeItem('cart')
		this.initCart()
	}

	private getCartResume(): Observable<Partial<ICart>> {
		return this.apiService.post<Partial<ICart>>(`${this.endPoint}/resume`, this.cart)
	}

	private saveCart() {
		this.getCartResume().subscribe(
			(cart: ICart) => {
				Object.assign(this.cart, cart)
				const data = JSON.stringify(this.cart)
				localStorage.setItem('cart', data)
				this.update$.emit(this.cart)
			},
			({ error }) => {
				this.alertService.openSnackBar(
					error?.message || JSON.stringify(error),
					SNACKBAR_TYPE.ERROR
				)
			}
		)
	}

	private initCart() {
		const data = localStorage.getItem('cart')
		if (data) {
			this.cart = JSON.parse(data)
			this.checkSelectedAdventures()
			return
		}

		this.cart = {
			books: [],
			affiliateRef: localStorage.getItem(STORAGE_CONSTANTS.AFFILIATE_REF)
		} as ICart
		this.saveCart()
	}
	public getParrainageSales(){
		return this.apiService.get<Partial<ICart>>(`${this.publicEndPoint}/affiliations/me/stats`)
	}
}
