import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { MaterialModules } from '@/shared/material.module'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { PipesModule } from '@/pipes/pipes.module'
import { TranslateModule } from '@ngx-translate/core'
import { HttpClient } from '@angular/common/http'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { InlineSVGModule } from 'ng-inline-svg'
import { NgxSpinnerModule } from 'ngx-spinner'
import { OverlayModule } from '@angular/cdk/overlay'
import { CookieService } from 'ngx-cookie-service'
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar'
import { MatDialogConfig } from '@angular/material/dialog'
import { FlexLayoutModule } from '@angular/flex-layout'

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http)
}
@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		...MaterialModules,
		PipesModule,
		TranslateModule,
		InlineSVGModule,
		NgxSpinnerModule,
		OverlayModule,
		FlexLayoutModule
	],
	providers: [
		CookieService,
		{
			provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
			useValue: {
				duration: 2000,
				horizontalPosition: 'center',
				verticalPosition: 'top'
			}
		},
		MatDialogConfig
	],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		...MaterialModules,
		PipesModule,
		TranslateModule,
		InlineSVGModule,
		NgxSpinnerModule,
		OverlayModule,
		FlexLayoutModule
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {}
