// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  width: 100vw;
  max-width: 500px;
}

.icon {
  font-size: 3rem;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImVycm9yLWFsZXJ0LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0MsWUFBQTtFQUNBLGdCQUFBO0FBQ0Q7O0FBRUE7RUFDQyxlQUFBO0FBQ0QiLCJmaWxlIjoiZXJyb3ItYWxlcnQuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIud3JhcHBlciB7XG5cdHdpZHRoOiAxMDB2dztcblx0bWF4LXdpZHRoOiA1MDBweDtcbn1cblxuLmljb24ge1xuXHRmb250LXNpemU6IDNyZW07XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/shared/alert/error-alert/error-alert.component.scss"],"names":[],"mappings":"AAAA;EACC,YAAA;EACA,gBAAA;AACD;;AAEA;EACC,eAAA;AACD;AACA,oaAAoa","sourcesContent":[".wrapper {\n\twidth: 100vw;\n\tmax-width: 500px;\n}\n\n.icon {\n\tfont-size: 3rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
