import { environment } from '../../environments/environment'
import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { map } from 'rxjs/operators'
import {
	IAddress,
	IUpdateDefaultAddress,
	IUserAddressCatalog
} from '../models/address.model'
import { ApiService } from './api.service'

@Injectable({
	providedIn: 'root'
})
export class AddressesService {
	userAddressCatalog = new BehaviorSubject<IUserAddressCatalog>({
		defaultAddresses: [],
		addresses: [],
		total: 0
	})
	private endPoint = `${environment.endPointV1}/addresses`

	constructor(private apiService: ApiService) {}

	createAddress(address: IAddress) {
		return this.apiService.post(this.endPoint, { ...address })
	}

	updateAddress(address: IAddress) {
		return this.apiService.put(this.endPoint + `/${address.id}`, { ...address })
	}

	updateDefaultAddress(data: IUpdateDefaultAddress) {
		return this.apiService
			.put<IUserAddressCatalog>(this.endPoint + `/default-address`, data)
			.pipe(
				map(response => {
					this.userAddressCatalog.next(response)
					return response
				})
			)
	}

	deleteAddress(addressId: string) {
		return this.apiService.delete(this.endPoint + `/${addressId}`, {}).pipe(
			map(response => {
				this.getUserAddress().subscribe()
				return response
			})
		)
	}

	getUserAddress() {
		return this.apiService
			.get<IUserAddressCatalog>(`${this.endPoint}/user-addresses`)
			.pipe(
				map((response: IUserAddressCatalog) => {
					this.userAddressCatalog.next(response)
					return response
				})
			)
	}
}
