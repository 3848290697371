import { PolarBehaviorSubject } from '@/shared/helper-classes/polar-behavior-subject'
import { LocalStorageService } from '@/shared/storage/local-storage.storage'
import { Injectable } from '@angular/core'
import { NONE_OPTION } from '../constants/character-style.constant'
import { STORAGE_CONSTANTS } from '../constants/constants'
import {
	ICharacter,
	ICharacterOptionProperties,
	ICharacterProperty
} from '../models/character.model'

@Injectable({ providedIn: 'root' })
export class CharacterService {
	_character: PolarBehaviorSubject<ICharacter>
	private _isInitialized = true
	private _initialCharacter: ICharacter = {
		eyes: null,
		skin: null,
		hair: null,
		accessories: null,
		outfit: null,
		gender: null,
		hairColor: null,
		personalizable: true
	}

	constructor(private localStorageService: LocalStorageService) {
		this.initCharacter()
	}

	get initialCharacter() {
		return this._initialCharacter
	}

	set initiatlCharacter(character: ICharacter) {
		this._initialCharacter = character
	}

	setInitialCharacter(character: ICharacter) {
		if (!character) return
		this._initialCharacter = character
	}

	private initCharacter() {
		this._character = new PolarBehaviorSubject<ICharacter>(this.getPreSaveCharacter())
	}

	get character() {
		return this._character
	}

	resetCharacter() {
		const newCharacter: ICharacter = {
			...this.initialCharacter
		}
		this.setCharacter(newCharacter)
	}

	get currentCharacter() {
		return this._character.getValue()
	}

	setCharacterProperty(
		propertyName: ICharacterProperty,
		value: any	) {
		this._character.next({ ...this.currentCharacter, [propertyName]: value })
		this.preSaveCharacter()
	}

	checkCharacterProperty(option: string, value: string) {
		const currentCharacter = this.currentCharacter
		return (
			(currentCharacter[option] && currentCharacter[option].name === value) ||
			currentCharacter[option] === value
		)
	}

	getCharacterProperty(propertyName: ICharacterProperty) {
		return this.currentCharacter[propertyName]
	}

	setCharacter(character: ICharacter) {
		this._character.next(character)
		this.preSaveCharacter()
	}

	preSaveCharacter() {
		if (!this.currentCharacter) return
		const currentCharacter = JSON.stringify(this.currentCharacter)
		const saved = this.localStorageService.getItem(STORAGE_CONSTANTS.PRESAVED_CHARACTER)
		if (saved) this.localStorageService.removeItem(STORAGE_CONSTANTS.PRESAVED_CHARACTER)
		this.localStorageService.setItem(
			STORAGE_CONSTANTS.PRESAVED_CHARACTER,
			currentCharacter
		)
	}

	get isFirstLoad() {
		return this._isInitialized
	}

	setFirstLoad(value: boolean) {
		this._isInitialized = value
	}

	private getPreSaveCharacter(): ICharacter {
		const saved = this.localStorageService.getItem(STORAGE_CONSTANTS.PRESAVED_CHARACTER)
		if (saved && saved !== 'undefined') {
			return JSON.parse(saved)
		}	
		else{
			return this.initialCharacter
		}	
	}
}
