import { Component, OnInit, Inject } from '@angular/core'
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar'
import { SNACKBAR_TYPE } from '@/app/constants/constants'
import { trigger, style, transition, animate } from '@angular/animations'

export interface VidoleSnackbarData {
	message: string
	type: SNACKBAR_TYPE
}

@Component({
	selector: 'app-vidole-snackbar',
	templateUrl: './vidole-snackbar.component.html',
	styleUrls: ['./vidole-snackbar.component.scss'],
	animations: [
		trigger('enterLeave', [
			transition(':enter', [
				style({ opacity: '0' }),
				animate('0.2s', style({ opacity: '1' }))
			]),
			transition(':leave', [
				style({ opacity: '1' }),
				animate('0.2s', style({ opacity: '0' }))
			])
		])
	]
})
export class VidoleSnackbarComponent implements OnInit {
	constructor(
		@Inject(MAT_SNACK_BAR_DATA) public data: VidoleSnackbarData,
		private snackbarRef: MatSnackBarRef<VidoleSnackbarComponent>
	) {}

	ngOnInit(): void {}
}
