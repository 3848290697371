import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { VidoleSnackbarComponent } from '../components/vidole-snackbar/vidole-snackbar.component'
import { SNACKBAR_TYPE } from '../constants/constants'

@Injectable({
	providedIn: 'root'
})
export class NotificationService {
	constructor(private snackbar: MatSnackBar) {}

	openSnackBar(message: string | null, type: SNACKBAR_TYPE) {
		if (message) {
			this.snackbar.openFromComponent(VidoleSnackbarComponent, {
				data: { message, type }
			})
		}
	}
}
