import { BehaviorSubject } from 'rxjs'
import { NgZone } from '@angular/core'
import 'zone.js/dist/zone'

declare let globalThis
export class PolarBehaviorSubject<T = any> extends BehaviorSubject<T> {
	private zone: /* alike */ NgZone = undefined
	constructor(_value: any) {
		super(_value)
		if (typeof globalThis.Zone !== 'undefined') {
			this.zone = globalThis.Zone && globalThis.Zone.current
		}
	}
	next(value: T) {
		if (!this.zone) return super.next(value)
		this.zone.run(() => {
			super.next(value)
		})
	}
}
