// import { NgxSpinnerService } from 'ngx-spinner'
import {
	emailRegExp,
	VIDOLE_FACEBOOK_URL,
	VIDOLE_INSTAGRAM_URL
} from '@/app/constants/constants'
import { FunctionService } from '@/app/services/function.service'
import { OnSubmitErrorStateMatcher } from '@/shared/helper-classes/error-state-matcher'
import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'
import { TranslateService } from '@ngx-translate/core'
import { take, takeUntil } from 'rxjs/operators'
import { BaseComponent } from '../base.component'

@Component({
	selector: 'app-contact-info-modal',
	templateUrl: './contact-info-modal.component.html',
	styleUrls: ['./contact-info-modal.component.scss']
})
export class ContactInfoModalComponent extends BaseComponent implements OnInit {
	infoForm: FormGroup
	public acceptedPolicy = false
	public submitted = false
	public urls = {
		facebook: VIDOLE_FACEBOOK_URL,
		instagram: VIDOLE_INSTAGRAM_URL
	}

	public errorMatcher = new OnSubmitErrorStateMatcher()

	constructor(
		private translate: TranslateService,
		public dialogRef: MatDialogRef<ContactInfoModalComponent>,
		private matDialog: MatDialog,
		private iconRegistry: MatIconRegistry,
		private sanitizer: DomSanitizer,
		private functionService: FunctionService
	) {
		super()
		this.iconRegistry.addSvgIcon(
			'facebook',
			this.sanitizer.bypassSecurityTrustResourceUrl('../../assets/logo/facebook.svg')
		)
		this.iconRegistry.addSvgIcon(
			'instagram',
			this.sanitizer.bypassSecurityTrustResourceUrl('../../assets/logo/instagram.svg')
		)
		this.dialogRef.disableClose = true
		this.dialogRef
			.backdropClick()
			.pipe(takeUntil(this.ngDestroyed$))
			.subscribe(() => {
				this.onClose()
			})
	}

	ngOnInit(): void {
		this.initForm()
	}

	initForm() {
		this.infoForm = new FormGroup({
			name: new FormControl('', [Validators.required]),
			telephone: new FormControl('', [Validators.pattern(/[0-9]{7,15}/)]),
			email: new FormControl('', [Validators.required, Validators.pattern(emailRegExp)]),
			enterprise: new FormControl('', []),
			iam: new FormControl('', []),
			message: new FormControl('', [])
		})
	}

	onClose() {
		const edited = this.infoForm.dirty && !this.submitted
		if (edited) {
			const dialog = this.functionService.openSmallDialog({
				title: this.translate.instant('warning'),
				content: this.translate.instant('sureToQuit'),
				hasOkBtn: true,
				hasCancelBtn: true
			})
			return dialog
				.afterClosed()
				.pipe(take(1))
				.subscribe((res: boolean) => {
					if (res) this.dialogRef.close()
				})
		}
		this.dialogRef.close()
	}

	onCheck() {
		this.acceptedPolicy = !this.acceptedPolicy
		if (document)
			document.getElementById('acceptedPolicy')['checked'] = this.acceptedPolicy
	}

	openUrl(url: string) {
		if (!this.functionService.isPlatformBrowser) return
		window.open(url)
	}

	submit() {
		if (this.infoForm.valid) {
			this.loading.content = true
			console.log(this.infoForm.value)

			this.loading.content = false
			this.dialogRef.close()
		}
	}
}
