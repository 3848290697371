import { TokenInterceptor } from '@/service/http-interceptor.service'
import { SharedModule } from '@/shared/shared.module'
import { BrowserStorage } from '@/shared/storage/browser.storage'
import { APP_STORAGE } from '@/shared/storage/storage.inject'
import { registerLocaleData } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http'
import localeFr from '@angular/common/locales/fr'
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core'
import { MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS } from '@angular/material/progress-spinner'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { InlineSVGModule } from 'ng-inline-svg'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { HttpRequestInterceptor } from './interceptors/http-request.interceptor'
import { LayoutModule } from './layout/layout.module'
import { ServicesModule } from './services/services.module'
import { LegalModule } from './pages/legals/legal.module'
registerLocaleData(localeFr)

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(
		http,
		'/assets/i18n/',
		'.json?cb=' + new Date().getTime()
	)
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient]
			}
		}),
		InlineSVGModule.forRoot({ baseUrl: window.origin }),
		BrowserModule,
		SharedModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		ServicesModule,
		/* ======= */
		LayoutModule,
		LegalModule
	],
	providers: [
		{
			provide: APP_STORAGE,
			useClass: BrowserStorage
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true
		},
		{
			provide: MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS,
			useValue: {
				diameter: 80,
				strokeWidth: 10
			}
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpRequestInterceptor,
			multi: true
		},
		{ provide: LOCALE_ID, useValue: 'fr' },
		{ provide: LOCALE_ID, useValue: 'en' }
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	bootstrap: [AppComponent]
})
export class AppModule {}
