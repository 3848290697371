import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http'
import { Injectable, Inject } from '@angular/core'
import { APP_STORAGE } from '@/shared/storage/storage.inject'
import { STORAGE_CONSTANTS } from '@/app/constants/constants'

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	constructor(@Inject(APP_STORAGE) private _appStorage: Storage) {}

	intercept(req: HttpRequest<any>, next: HttpHandler) {
		const token = this._appStorage.getItem(STORAGE_CONSTANTS.AUTH_TOKEN)
		const request = req.clone({
			setHeaders: {
				Authorization: `Bearer ${token}`
			}
		})
		return next.handle(request)
	}
}
