import { environment } from '../../environments/environment'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import {
	AffiliationData,
	AffiliationPayment,
	AffiliationPaymentStats,
	AffiliationStats,
	IPaymentSettings,
	ISocialNetworks
} from '../models/affiliation.interfaces'
import { ApiService } from './api.service'

@Injectable({
	providedIn: 'root'
})
export class AffiliationService {
	affiliationData$ = new BehaviorSubject<AffiliationData>(null)
	affiliationStats$ = new BehaviorSubject<AffiliationStats>(null)
	payments$ = new BehaviorSubject<AffiliationPayment[]>([])
	affiliationPaymentStats$ = new BehaviorSubject<AffiliationPaymentStats>(null)

	private endPoint = `${environment.endPointV1}/affiliations`

	constructor(private apiService: ApiService) {}

	getInfos(): Observable<AffiliationData> {
		return this.apiService.get<any>(`${this.endPoint}/me`).pipe(
			map(response => {
				this.affiliationData$.next(response)
				return response
			})
		)
	}

	getStats(startDate?: string, endDate?: string): Observable<AffiliationStats> {
		return this.apiService
			.get<any>(
				`${this.endPoint}/me/stats?` +
					`startDate=${startDate || ''}&endDate=${endDate || ''}`
			)
			.pipe(
				map(response => {
					this.affiliationStats$.next(response)
					return response
				})
			)
	}

	getPaymentStats(): Observable<AffiliationStats> {
		return this.apiService.get<any>(`${this.endPoint}/me/payment-stats`).pipe(
			map(response => {
				this.affiliationPaymentStats$.next(response)
				return response
			})
		)
	}

	getPayments(): Observable<AffiliationPayment[]> {
		return this.apiService.get<any>(`${this.endPoint}/me/payments`).pipe(
			map(response => {
				this.payments$.next(response)
				return response
			})
		)
	}

	updateSocialNetworks(data: ISocialNetworks) {
		return this.apiService.put(`${this.endPoint}/me/social-networks`, data).pipe(
			map(response => {
				this.getInfos().subscribe()
				return response
			})
		)
	}

	updatePaymentSettings(data: IPaymentSettings) {
		return this.apiService.put(`${this.endPoint}/me/payment-settings`, data).pipe(
			map(response => {
				this.getInfos().subscribe()
				return response
			})
		)
	}
}
