import { APP_STORAGE } from '@/shared/storage/storage.inject'
import { Component, Inject, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { LangChangeEvent, TranslateService } from '@ngx-translate/core'
// import packageJson from '../../package.json'
import { STORAGE_CONSTANTS } from './constants/constants'
import { TranslationLoaderService } from './services/translation-loader.service'

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	title = 'vidole-web'
//	appVersion = packageJson.version

	constructor(
		private translate: TranslateService,
		private translationLoader: TranslationLoaderService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		@Inject(APP_STORAGE) private appStorage: Storage
	) {
		this.translate.addLangs(['en', 'fr'])
		const defaultLanguage = 'fr'
		this.translate.setDefaultLang(defaultLanguage)
		this.translate.currentLang = defaultLanguage
		this.translate.onLangChange.subscribe(
			({ lang }: LangChangeEvent) => {
				this.appStorage.setItem(STORAGE_CONSTANTS.LANGUAGE, lang)
			},
			err => {
				console.error(err)
			}
		)
	}

	ngOnInit() {
		this.activatedRoute.queryParams.subscribe(data => {
			if (data['ref']) {
				localStorage.setItem(STORAGE_CONSTANTS.AFFILIATE_REF, data['ref'])
				this.router.navigateByUrl('/')
			}
		})

		// const savedVersion = localStorage.getItem('VERSION')
		// if (savedVersion !== this.appVersion) {
		// 	localStorage.clear()
		// }

		// localStorage.setItem('VERSION', this.appVersion)
	}
}
