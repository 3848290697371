import { SharedModule } from '@/shared/shared.module'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { CookieComponent } from './cookie/cookie.component'
import { LegalComponent } from './legal.component'
import { MentionLegalComponent } from './mention-legal/mention-legal.component'
import { PrivacyComponent } from './privacy/privacy.component'
import { SaleLegalComponent } from './sale-legal/sale-legal.component'

const routes: Routes = [
	{
		path: '',
		component: LegalComponent,
		children: [
			{
				path: 'sale-legal',
				component: SaleLegalComponent
			},
			{
				path: 'mention-legal',
				component: MentionLegalComponent
			},
			{
				path: 'privacy',
				component: PrivacyComponent
			},
			{
				path: 'cookie',
				component: CookieComponent
			},
			{
				path: '**',
				redirectTo: 'sale-legal',
				pathMatch: 'full'
			}
		]
	}
]

@NgModule({
	declarations: [
		SaleLegalComponent,
		LegalComponent,
		MentionLegalComponent,
		PrivacyComponent,
		CookieComponent
	],
	imports: [CommonModule, RouterModule.forChild(routes), SharedModule],
	exports: [],
	providers: []
})
export class LegalModule {}
