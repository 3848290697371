import { ICharacterImage } from '../models/character-image.model'

export interface IImagePropertyOption {
	name: string
	presetImage: string
	displayPrependPath: string
	displayPostpendPath: string
	localName: string
	fileName: string
}

export interface IColorPropertyOption {
	name: string
	displayColor: string
	localName: string
	fileName: string
	apiProperty: ICharacterImage
}

export interface IAccessoriesOption {
	name: string
	displayImage: string
	localName: string
	fileName: string
}

export const NONE_OPTION = {
	name: 'none',
	localName: 'none',
	fileName: 'none.svg',
	type: 'accessories',
	image: null,
	id: null,
	imageId: '',
	userId: '',
	gender: null
}

export const CHARACTER_API_DISPLAY_PROPERTY_KEY = {
	OUTFIT_DISPLAY: 'outfit-display',
	ACCESSORIES_DISPLAY: 'accessories-display',
	HAIR_DISPLAY: 'hair-display',
	EYE_DISPLAY: 'eyes-display'
}

export const CHARACTER_API_PROPERTY_KEY = {
	ACCESSORIES: 'accessories',
	EYES: 'eyes',
	HAIR_COLORED: 'hair-colored',
	OUTFIT: 'outfit',
	SKIN: 'skin',
	SKIN_ALB: 'skin-alb'
}

// IMPROTANT: CHANGING THIS ORDER MAY RESULT IN CHANGING THE APP's PERFOMANCE
export const HAIR_NAME_ORDER = ['hair-1', 'hair-2', 'hair-3', 'hair-4', 'hair-5']

// EXACT_MATCH_COLOR_ORDER
export const EYE_NAME_ORDER = ['eyes-brown', 'eyes-blue', 'eyes-green', 'eyes-pink']
export const EYE_COLOR_ORDER = [
	'rgba(103,64,0,1)',
	'rgba(79,187,232,1)',
	'rgba(77,200,77,1)',
	'rgba(240,110,110,1)'
]

export const ACCESSORIES_NAME_ORDER = ['glasses-1', 'glasses-2']

// EXACT MATCH COLOR ORDER
export const SKIN_NAME_ORDER = [
	'skin-brown-light',
	'skin-brown',
	'skin-brown-dark',
	'skin-alb'
]

export const SKIN_COLOR_ORDER = [
	{ name: 'skin-brown-light', color: 'rgba(217,148,87,1)' },
	{ name: 'skin-brown', color: 'rgba(180,81,42,1)' },
	{ name: 'skin-alb', color: 'rgba(255,240,226,1)' }
]
export const SKIN_ALB_COLOR = 'rgba(75,26,26,1)'

export const OUTFIR_NAME_ORDER = [
	'outfit-1',
	'outfit-2',
	'outfit-3',
	'outfit-4',
	'outfit-5'
]

export const CHARACTER_PROPERTY_MAP = {
	[CHARACTER_API_PROPERTY_KEY.ACCESSORIES]: ACCESSORIES_NAME_ORDER,
	[CHARACTER_API_DISPLAY_PROPERTY_KEY.ACCESSORIES_DISPLAY]: ACCESSORIES_NAME_ORDER,
	[CHARACTER_API_PROPERTY_KEY.SKIN]: SKIN_NAME_ORDER,
	[CHARACTER_API_PROPERTY_KEY.EYES]: EYE_NAME_ORDER,
	[CHARACTER_API_PROPERTY_KEY.HAIR_COLORED]: HAIR_NAME_ORDER,
	[CHARACTER_API_DISPLAY_PROPERTY_KEY.HAIR_DISPLAY]: HAIR_NAME_ORDER,
	[CHARACTER_API_PROPERTY_KEY.OUTFIT]: OUTFIR_NAME_ORDER,
	[CHARACTER_API_DISPLAY_PROPERTY_KEY.OUTFIT_DISPLAY]: OUTFIR_NAME_ORDER
}

export const USER_CHARACTER_PROPERTY_KEY = {
	EYES: 'eyes',
	SKIN: 'skin',
	HAIR: 'hair',
	ACCESSORIES: 'accessories',
	OUTFIT: 'outfit'
}
