import { Component, OnInit } from '@angular/core'
import { OwlOptions } from 'ngx-owl-carousel-o'

@Component({
	selector: 'app-global-alert',
	templateUrl: './global-alert.component.html',
	styleUrls: ['./global-alert.component.scss']
})
export class GlobalAlertComponent implements OnInit {
	customOptions: OwlOptions = {
		loop: true,
		fluidSpeed: true,
		autoplayTimeout: 10000,
		lazyLoad: true,
		autoplayHoverPause: true,
		autoplay: true,
		autoHeight: false,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: false,
		dots: false,
		navSpeed: 700,
		responsive: {
			0: {
				items: 1
			}
		},
		nav: false
	}

	slides: Array<{ message: string }> = [
		{
			message: `Le Tome 1 fait peau neuve. Découvrez la nouvelle couverture.`
		},
		{
			message: `Livraison gratuite en France à partir de 2 livres commandés`
		},
		{
			message: `10% automatique de réduction à partir de 3 livres dans votre panier.`
		}
	]

	alertOpened = !false

	constructor() {}

	ngOnInit(): void {}

	ngAfterViewInit(): void {
		this.toggleAlert(this.alertOpened)
	}

	toggleAlert(open: boolean) {
		this.alertOpened = open
	}
}
