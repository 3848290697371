import { Injectable } from '@angular/core'
import { ApiService } from './api.service'
import { ClientFeedBackInput, ClientFeedbackResponse } from '../models/feedback.interface'
import { environment } from '../../environments/environment'

@Injectable()
export class ClientFeedbackService {
	private endPoint = `${environment.endPointV1}/feedbacks`

	constructor(private apiService: ApiService) {}

	createFeedback(feedback: ClientFeedBackInput) {
		return this.apiService.post<ClientFeedbackResponse>(this.endPoint, feedback)
	}

	getFeedBacks() {
		return this.apiService.get<ClientFeedbackResponse[]>(this.endPoint)
	}
}
