import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
export interface SmallInformModalData {
	title: string
	content: string
	hasOkBtn?: boolean
	okBtnText?: string
	hasCancelBtn?: boolean
	cancelBtnText?: string
	primaryBtn?: 'ok' | 'cancel'
	classes?: string[]
	subContent?: string
}

@Component({
	selector: 'app-small-inform-modal',
	templateUrl: './small-inform-modal.component.html',
	styleUrls: ['./small-inform-modal.component.scss']
})
export class SmallInformModalComponent implements OnInit {
	public modalData: SmallInformModalData
	constructor(
		private dialogRef: MatDialogRef<SmallInformModalComponent>,
		@Inject(MAT_DIALOG_DATA) data: SmallInformModalData
	) {
		this.modalData = data
	}

	ngOnInit(): void {}

	closeModal(result = false) {
		this.dialogRef.close(result)
	}
}
