import { environment } from '../../environments/environment'
import { ErrorAlertComponent } from '@/shared/alert/error-alert/error-alert.component'
import { IAlertData } from '@/shared/alert/interfaces'
import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'
import { STORAGE_CONSTANTS } from '../constants/constants'
import { IJwtPayload, SignInDto } from '../models/auth.model'
import { ICreateUser } from '../models/user.model'
import { ApiService } from './api.service'

@Injectable({ providedIn: 'root' })
export class AuthService {
	private authEndPoint = `${environment.endPointV1}/auth`

	constructor(
		private apiService: ApiService,
		private router: Router,
		private dialog: MatDialog
	) {}

	signIn(signInDto: SignInDto) {
		return this.apiService
			.post<IJwtPayload>(`${this.authEndPoint}/sign-in`, signInDto)
			.pipe(
				map((payload: IJwtPayload) => {
					this.saveJwtPayload(payload)
					return payload
				})
			)
	}

	getAuthInfos(): IJwtPayload {
		return JSON.parse(localStorage.getItem('JWY_PAYLOAD'))
	}

	getAuthorizationToken(): string {
		try {
			return this.getAuthInfos().token
		} catch (error) {
			return undefined
		}
	}

	signUp(signUpDto: ICreateUser) {
		return this.apiService
			.post<IJwtPayload>(`${this.authEndPoint}/sign-up`, signUpDto)
			.pipe(
				map((payload: IJwtPayload) => {
					this.saveJwtPayload(payload)
					return payload
				})
			)
	}

	logout() {
		const { refreshToken } = this.getAuthInfos()
		return this.apiService.post(`${this.authEndPoint}/sign-out`, { refreshToken }).pipe(
			map(
				response => {
					this.router.navigateByUrl('/login')
					this.clearJwtPayload()
					return response
				},
				error => {}
			)
		)
	}

	signOut(refreshToken: string) {
		return this.apiService.post(`${this.authEndPoint}/sign-out`, { refreshToken })
	}

	sendForgotPasswordRequest(email: string) {
		return this.apiService.post<{ success: boolean; alreadySent?: boolean }>(
			`${this.authEndPoint}/forgot-password`,
			{ email }
		)
	}

	checkPasswordResetToken(token: string): Observable<{ expired: boolean }> {
		if (!token) {
			this.redirectIfPasswordResetTokenExpired()
			return of({ expired: true })
		}
		return this.apiService
			.post(`${this.authEndPoint}/check-reset-password-token`, { token })
			.pipe(
				map((data: any) => {
					if (data.expired) {
						this.redirectIfPasswordResetTokenExpired()
						localStorage.removeItem(STORAGE_CONSTANTS.RESET_PASSWORD_TOKEN)
					}
					return data
				})
			)
	}

	createNewPassword(token: string, password: string) {
		return this.apiService
			.post<{ success: boolean; expiredToken?: boolean }>(
				`${this.authEndPoint}/create-password`,
				{ token, password }
			)
			.pipe(
				map(response => {
					if (response.success) {
						localStorage.removeItem(STORAGE_CONSTANTS.RESET_PASSWORD_TOKEN)
					}
					if (response.expiredToken) {
						this.redirectIfPasswordResetTokenExpired()
					}
					return response
				})
			)
	}

	clearJwtPayload() {
		localStorage.removeItem(STORAGE_CONSTANTS.JWY_PAYLOAD)
	}

	private redirectIfPasswordResetTokenExpired() {
		this.router.navigateByUrl('/forgot-password')
		this.dialog.open(ErrorAlertComponent, {
			hasBackdrop: true,
			backdropClass: 'vidole-backdrop-deep',
			data: {
				message: `Le lien de réinitialisation de mot de passe a expiré. Veuillez recommencer!`
			} as IAlertData
		})
	}

	private saveJwtPayload(payload: IJwtPayload) {
		localStorage.setItem(STORAGE_CONSTANTS.CURRENT_USER, JSON.stringify(payload.user))
		delete payload.user
		localStorage.setItem(STORAGE_CONSTANTS.JWY_PAYLOAD, JSON.stringify(payload))
	}
}
