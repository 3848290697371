import { environment } from '../../environments/environment'
import { PolarBehaviorSubject } from '@/shared/helper-classes/polar-behavior-subject'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { take } from 'rxjs/operators'
import { IShippingPrice } from '../models/shipping.interface'

@Injectable({
	providedIn: 'root'
})
export class ShippingService {
	private baseEndPoint = `${environment.baseEndPoint}/v1/delivery`

	private currentParam: {
		amount: number
		countryCode: string
	} = {
		amount: 0,
		countryCode: ''
	}

	public _deliveryService: PolarBehaviorSubject<IShippingPrice> =
		new PolarBehaviorSubject(null)
	public _deliveryOptions: PolarBehaviorSubject<IShippingPrice[]> =
		new PolarBehaviorSubject([])

	constructor(private httpClient: HttpClient) {}

	public get deliveryMethod() {
		return this._deliveryService.asObservable()
	}

	public get deliveryOptions() {
		return this._deliveryOptions.asObservable()
	}

	public get currentDeliveryService() {
		return this._deliveryService.getValue()
	}

	public get currentDeliveryOptions() {
		return this._deliveryOptions.getValue()
	}

	public hasParamChanged(countryCode = 'fr', amount: number) {
		return !(
			countryCode === this.currentParam.countryCode && amount === this.currentParam.amount
		)
	}

	public async onDeliveryAddressChange(countryCode = 'fr', amount: number) {
		if (!this.hasParamChanged(countryCode, amount)) {
			return this._deliveryOptions.next(this.currentDeliveryOptions)
		}
		this.currentParam = {
			amount,
			countryCode
		}

		this.getShippingPrice(countryCode, amount)
			.pipe(take(1))
			.subscribe((deliveryOptions: IShippingPrice[]) => {
				this._deliveryOptions.next(deliveryOptions)
			})
	}

	public onSelectDeliveryService(deliveryService: IShippingPrice) {
		this._deliveryService.next(deliveryService)
	}

	getShippingPrice(countryCode: string, amount: number) {
		return this.httpClient.get<IShippingPrice[]>(
			`${this.baseEndPoint}/calculate-shipping-price`,
			{
				params: {
					amount: amount.toString(),
					countryCode
				}
			}
		)
	}
}
