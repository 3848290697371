import { BOOK_TYPE } from '@/app/constants/constants'
import { Book } from '@/app/models/book-customizer.interfaces'
import { BooksService, CartService } from '@/app/services'
import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Router } from '@angular/router'

export interface IBookPreviewModalData {
	book: Book
}

@Component({
	selector: 'app-book-preview-modal',
	templateUrl: './book-preview-modal.component.html',
	styleUrls: ['./book-preview-modal.component.scss']
})
export class BookPreviewModalComponent implements OnInit {
	BOOK_TYPE = BOOK_TYPE

	get book() {
		return this.data.book
	}

	get alreadyAddedToCart(): boolean {
		return (
			this.cartService.getCart().books.findIndex(_book => {
				return _book.bookRef === this.book.id
			}) > -1
		)
	}

	constructor(
		private booksService: BooksService,
		private cartService: CartService,
		private router: Router,
		private dialogRef: MatDialogRef<BookPreviewModalComponent>,
		@Inject(MAT_DIALOG_DATA) private data: IBookPreviewModalData
	) {}

	ngOnInit(): void {}

	customize() {
		this.booksService.selectedBook$.next(this.book)
		this.router.navigateByUrl('/customizer')
		this.close()
	}

	addToCart() {
		this.cartService.addBook({
			name: this.book.title,
			type: this.book.type,
			quantity: 1,
			bookRef: this.book.id,
			unitPrice: this.book.price
		})
	}

	close() {
		this.dialogRef.close()
	}
}
