import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { menuItems } from '../constants'

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
	@Input() cartItems?: number
	@Output() openCart = new EventEmitter()

	public menuItems = menuItems

	constructor() {}

	ngOnInit(): void {}
}
