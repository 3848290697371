import { ISupportedCountry } from '@/app/models/shipping.interface'
import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'orderBy' })
export class OrderrByPipe implements PipeTransform {
	transform(
		records: Array<ISupportedCountry>,
		args?: { lang: string }
	): ISupportedCountry[] {
		return [
			records[0], // priority countries: France, Switzerland, Belgium
			records[1],
			records[2],
			...records.slice(3).sort((a, b) => {
				if (a[args.lang] < b[args.lang]) {
					return -1
				} else if (a[args.lang] > b[args.lang]) {
					return 1
				} else {
					return 0
				}
			})
		]
	}
}
