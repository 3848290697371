import { NgModule } from '@angular/core'
import { ClientFeedbackService } from './client-feedback.service'
import { SubscribeEmailService } from './subscribe-email.service'
import { HttpClientModule } from '@angular/common/http'
import { TranslationLoaderService } from './translation-loader.service'
import { RouterModule } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
@NgModule({
	imports: [HttpClientModule, RouterModule, TranslateModule],
	providers: [ClientFeedbackService, SubscribeEmailService, TranslationLoaderService]
})
export class ServicesModule {}
