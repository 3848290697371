import { ObjectUnsubscribedError, Subject, Subscriber, Subscription } from 'rxjs'

export class BehaviorSubject<T> extends Subject<T> {
	private valueSetted = false
	private _value?: T

	constructor(value?: T) {
		super()
		if (arguments.length) {
			this.setValue(value)
		}
	}

	get value(): T | undefined {
		return this.getValue()
	}

	/** @internal */
	/** @deprecated This is an internal implementation detail, do not use. */
	_subscribe(subscriber: Subscriber<T>): Subscription {
		const subscription = super.subscribe(subscriber)
		if (this.valueSetted && !subscription.closed) {
			subscriber.next(this._value)
		}
		return subscription
	}

	getValue(): T | undefined {
		const { hasError, thrownError, _value } = this
		if (hasError) {
			throw thrownError
		}
		if (this.closed) {
			throw new ObjectUnsubscribedError()
		}
		return _value
	}

	setValue(value: T | undefined) {
		this._value = value
		if (!this.valueSetted) {
			this.valueSetted = true
		}
	}

	next(value: T): void {
		super.next((this._value = value))
	}
}
