import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { MatButtonModule } from '@angular/material/button'
import { MatRippleModule } from '@angular/material/core'
import { MatIconModule } from '@angular/material/icon'
import { MatListModule } from '@angular/material/list'
import { MatMenuModule } from '@angular/material/menu'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatToolbarModule } from '@angular/material/toolbar'
import { RouterModule } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { CarouselModule } from 'ngx-owl-carousel-o'
import { FooterComponent } from './footer/footer.component'
import { GlobalAlertComponent } from './global-alert/global-alert.component'
import { HeaderComponent } from './header/header.component'
import { LanguageBarComponent } from './language-bar/language-bar.component'
import { LayoutComponent } from './layout.component'
import { TopBarComponent } from './top-bar/top-bar.component'
import { BasketComponent } from './basket/basket.component'
import { MiniBookCardComponent } from './mini-book-card/mini-book-card.component'
import { ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { FooterBannerComponent } from './footer-banner/footer-banner.component'

@NgModule({
	declarations: [
		LayoutComponent,
		FooterComponent,
		TopBarComponent,
		HeaderComponent,
		LanguageBarComponent,
		GlobalAlertComponent,
		BasketComponent,
		MiniBookCardComponent,
		FooterBannerComponent
	],
	exports: [LayoutComponent],
	imports: [
		CommonModule,
		RouterModule,
		TranslateModule,

		// Materials modules
		MatButtonModule,
		MatIconModule,
		MatToolbarModule,
		MatRippleModule,
		MatMenuModule,
		MatSidenavModule,
		FlexLayoutModule,
		MatListModule,
		CarouselModule,
		ReactiveFormsModule,
		BrowserModule
	]
})
export class LayoutModule {}
