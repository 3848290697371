import { Component, OnInit } from '@angular/core'
import { MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { HubspotService } from '@/app/services/hubspot.service'
import { newslatterSubscribtion } from '@/app/models/newslatter-subscribtion.model'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
@Component({
	selector: 'app-footer-banner',
	templateUrl: './footer-banner.component.html',
	styleUrls: ['./footer-banner.component.scss']
})
export class FooterBannerComponent implements OnInit {
	newsletterForm: FormGroup
	newsletterFormSubmitted = false
	responseMessage = ''
	private unsubscribe$: Subject<void> = new Subject<void>()
	constructor(private fb: FormBuilder, private hubspotServ: HubspotService) {}

	ngOnInit() {
		this.newsletterForm = this.fb.group({
			email: ['', [Validators.required, Validators.email]],
			subscribe: [false, Validators.requiredTrue]
		})
	}
	get fnewsletterForm(): { [key: string]: AbstractControl } {
		return this.newsletterForm.controls
	}
	onSubmitNewsLatterForm() {
		this.newsletterFormSubmitted = true
		if (this.newsletterForm.invalid) {
			return
		}
		this.submitNewsLatterForm()
	}
	submitNewsLatterForm() {
		const data: newslatterSubscribtion = {
			fields: [
				{
					objectTypeId: '0-1',
					name: 'email',
					value: this.newsletterForm.get('email').value
				}
			]
		}
		this.hubspotServ
			.submitNewslatterForm(data)
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe({
				next: v => {
					this.showSucessFormMessage()
				},
				error: e => this.showErrorFormMessage()
			})
	}
	showSucessFormMessage() {
		this.responseMessage = 'Merci pour votre inscription à notre newsletter !'
		this.newsletterFormSubmitted = false
		this.newsletterForm.reset()
		setTimeout(() => {
			this.responseMessage = ''
		}, 10000)
	}
	showErrorFormMessage() {
		this.responseMessage =
			"Nous avons rencontré un problème lors de l'inscription à notre newsletter."
		this.newsletterFormSubmitted = false
		this.newsletterForm.reset()
		setTimeout(() => {
			this.responseMessage = ''
		}, 5000)
	}
}
