import {
	HttpErrorResponse,
	HttpHandler,
	HttpInterceptor,
	HttpRequest
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Router } from '@angular/router'
import { throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { VidoleSnackbarComponent } from '../components/vidole-snackbar/vidole-snackbar.component'
import { SNACKBAR_TYPE } from '../constants/constants'
import { AuthService } from '../services/auth.service'

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
	constructor(
		public authService: AuthService,
		private router: Router,
		private snackbar: MatSnackBar
	) {}

	intercept(req: HttpRequest<any>, next: HttpHandler) {
		if (req.headers.get('X-Skip-Interceptor') === 'true') {
			// If X-Skip-Interceptor header is present, pass the request as is
			return next.handle(req)
		}
		const token = this.authService.getAuthorizationToken()
		const cloneReq = req.clone({
			headers: token ? req.headers.set('Authorization', 'Bearer ' + token) : undefined
		})
		return next.handle(cloneReq).pipe(
			catchError((httpError: HttpErrorResponse) => {
								const response = httpError.error
				if (response?.message === 'tokenExpired') {
					this.clearJWTAndRedirectToLoginPage()
				} else {
					switch (httpError.status) {
						case 401:
							this.clearJWTAndRedirectToLoginPage()
							break

						default:
							if (httpError.status !== 502) {
								this.snackbar.openFromComponent(VidoleSnackbarComponent, {
									duration: 5000,
									data: { message: response.message, type: SNACKBAR_TYPE.ERROR }
								})
								console.error(response.message || response)
								break
							}
					}
				}

				return throwError(response)
			})
		)
	}

	private clearJWTAndRedirectToLoginPage() {
		this.authService.clearJwtPayload()
		this.router.navigateByUrl('/login')
	}
}
