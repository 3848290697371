import { PolarBehaviorSubject } from '@/shared/helper-classes/polar-behavior-subject'
import { LocalStorageService } from '@/shared/storage/local-storage.storage'
import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { LANGUAGE_CONSTANTS, STORAGE_CONSTANTS } from '../constants/constants'
import { IBook } from '../models/book.model'
import { CharacterService } from './character.service'
import { FunctionService } from './function.service'
import { HeroService } from './hero.service'

/**
 * This component will clear all of it's data after user add the book to hos cart
 * This means after the /adventure-validate route, all will be reset to null
 */
@Injectable({
	providedIn: 'root'
})
export class BookService {
	private _book: PolarBehaviorSubject<IBook>
	private _indexEditBook: PolarBehaviorSubject<number>
	currentBookLanguage: string
	constructor(
		private translate: TranslateService,
		private localStorage: LocalStorageService,
		private characterService: CharacterService,
		private heroService: HeroService
	) {
		this.currentBookLanguage =
			localStorage.getItem('currentBookLang') || this.translate.currentLang

		this.init()
	}

	private init() {
		let bookItem = null
		const currentBookItem = this.localStorage.getItem(STORAGE_CONSTANTS.BOOK)
		if (currentBookItem) bookItem = FunctionService.parseObject(currentBookItem)
		this._book = new PolarBehaviorSubject(bookItem)

		let bookIndex = null
		const bookIndexData = this.localStorage.getItem(STORAGE_CONSTANTS.EDIT_BOOK_INDEX)
		if (bookIndexData) bookIndex = FunctionService.parseObject(bookIndexData)
		this._indexEditBook = new PolarBehaviorSubject(bookIndex)
	}

	getBookFullLanguage(
		lang: LANGUAGE_CONSTANTS = this.currentBookLanguage as LANGUAGE_CONSTANTS
	): string {
		return lang === LANGUAGE_CONSTANTS.ENGLISH ? 'anglais' : 'français'
	}

	public get currentBook(): undefined | IBook {
		return this._book.getValue()
	}

	public setBook(book: IBook) {
		this.currentBookLanguage = book ? book.language : this.translate.currentLang
		this._book.next(book)
		this.saveBook()
		if (book) {
			this.characterService.setCharacter(book.character)
			this.heroService.setHero(book.hero)
		}
	}

	public setBookProperty<T = any>(propertyName: keyof IBook, value: T) {
		const currentBook = Object.assign(this.currentBook, { [propertyName]: value })
		this._book.next(currentBook)
		this.saveBook()
	}

	public clear() {
		this._book.next(null)
		this._indexEditBook.next(null)
		this.removeCache()
	}

	public get book() {
		return this._book
	}

	public setEditBookIndex(index: number) {
		this._indexEditBook.next(index)
		const currentBookIndex = FunctionService.stringify(this.currentBookIndex)
		this.localStorage.setItem(STORAGE_CONSTANTS.EDIT_BOOK_INDEX, currentBookIndex)
	}

	private saveBook() {
		const currentBook = this.currentBook
		const currentBookData = FunctionService.stringify(currentBook)
		this.localStorage.setItem(STORAGE_CONSTANTS.BOOK, currentBookData)
	}

	private removeCache() {
		this.localStorage.removeItem(STORAGE_CONSTANTS.BOOK)
		this.localStorage.removeItem(STORAGE_CONSTANTS.EDIT_BOOK_INDEX)
	}

	public get hasAdventures() {
		const currentBook = this.currentBook
		return currentBook && currentBook.adventures && currentBook.adventures.length
	}

	public get currentBookIndex() {
		return this._indexEditBook.getValue()
	}
}
