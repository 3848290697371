import { NgModule } from '@angular/core'
import { SafeHTMLPipe } from './safe-html.pipe'
import { OrderrByPipe } from './order-by.pipe'

@NgModule({
	declarations: [SafeHTMLPipe, OrderrByPipe],
	imports: [],
	exports: [SafeHTMLPipe, OrderrByPipe],
	providers: []
})
export class PipesModule {}
