import { isPlatformBrowser, ViewportScroller } from '@angular/common'
import { Inject, Injectable, PLATFORM_ID } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { TranslateService } from '@ngx-translate/core'
import {
	SmallInformModalComponent,
	SmallInformModalData
} from '../components/small-inform-modal/small-inform-modal.component'
import {
	AnimateCssElement,
	AnimationRepeatType,
	AnimationSpeedType,
	AnimationType
} from '../shared-definitions/animate-css.definition'

@Injectable({ providedIn: 'root' })
export class FunctionService {
	constructor(
		@Inject(PLATFORM_ID) private platformId: string,
		private matDialog: MatDialog,
		private translate: TranslateService,
		private viewportScroller: ViewportScroller
	) {}

	public get isMobileBrowser(): boolean {
		let check = false
		if (this.isPlatformBrowser) {
			;(function (a) {
				if (
					/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
						a
					) ||
					/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
						a.substr(0, 4)
					)
				)
					check = true
			})(navigator.userAgent || navigator.vendor || window['opera'])
		}
		return check
	}

	public get isPlatformBrowser(): boolean {
		return isPlatformBrowser(this.platformId)
	}

	private static instance: typeof FunctionService = FunctionService

	public static parseObject<T = any>(data: string): T {
		try {
			const parsed = JSON.parse(data)
			return parsed
		} catch (err) {
			return null
		}
	}

	public static cloneDeep<T = any>(object: T): T {
		try {
			const str = JSON.stringify(object)
			const newObject = JSON.parse(str)
			return newObject
		} catch (err) {
			return null
		}
	}

	public static stringify(entity: any): string {
		if (typeof entity === 'string') {
			return entity
		}
		if (Array.isArray(entity)) {
			return '[' + entity.map(this.stringify).join(', ') + ']'
		}
		if (entity == null) {
			return '' + entity
		}

		let result = null
		try {
			result = JSON.stringify(entity)
		} catch (err) {
			result = null
		}

		if (result == null) {
			return '' + result
		}
		return result
	}

	public static getHtmlContent(template: string) {
		const dormParser = new DOMParser()
		return dormParser.parseFromString(template, 'text/html').documentElement.textContent
	}

	public static getKeys<T = string>(object: object): T {
		return Object.keys(object) as unknown as T
	}

	public cloneDeep<T = any>(object: any): T {
		return this.callFunction('cloneDeep', object)
	}

	private getSelf() {
		const { instance } = FunctionService
		return instance === (this as any) ? FunctionService : instance
	}

	private callFunction(functionName: keyof FunctionService, ...args: any[]) {
		const instance = this.getSelf()
		const func = instance && instance[functionName]
		return func && func.call(instance, ...args)
	}

	public parseObject<T = any>(data: /* stringified object */ string): T {
		return this.callFunction('parseObject', data)
	}

	public stringify(entity: any) {
		return this.callFunction('stringify', entity)
	}

	public encryptEntity(entity: any) {
		return this.callFunction('encryptEntity', entity)
	}

	public decryptEntity(encryptedEntity: any) {
		return this.callFunction('decryptEntity', encryptedEntity)
	}

	public createHash(str: string) {
		return this.callFunction('createHash', str)
	}

	public openSmallDialog({
		title,
		content,
		hasOkBtn,
		okText,
		hasCancelBtn,
		cancelText,
		primaryBtn,
		customClasses,
		subContent
	}: {
		title: string
		content?: string
		hasOkBtn: boolean
		okText?: string
		hasCancelBtn: boolean
		cancelText?: string
		primaryBtn?: 'ok' | 'cancel'
		customClasses?: string[]
		subContent?: string
	}) {
		return this.matDialog.open<SmallInformModalComponent, SmallInformModalData>(
			SmallInformModalComponent,
			{
				hasBackdrop: true,
				data: {
					title: title || this.translate.instant('success'),
					hasOkBtn,
					hasCancelBtn,
					content: content || '',
					okBtnText: okText || this.translate.instant('ok'),
					cancelBtnText: cancelText || this.translate.instant('cancel'),
					primaryBtn: primaryBtn || 'ok',
					classes: customClasses,
					subContent
				},
				backdropClass: 'vidole-backdrop',
				autoFocus: true
			}
		)
	}

	public scrollToPosition(position: [number, number]) {
		if (!this.isPlatformBrowser) return
		this.viewportScroller.scrollToPosition(position)
	}

	public scrollToAnchor(elementId: string) {
		if (!this.isPlatformBrowser) return
		this.viewportScroller.scrollToAnchor(elementId)
	}

	public testValue(value1: string, value2: string): boolean {
		return this.createHash(value1) === this.createHash(value2)
	}

	public constructAnimationElement({
		elementQuery,
		animation,
		type,
		replaceClass,
		removeAfterDone,
		callback
	}: {
		elementQuery: string
		animation: AnimationType
		type: 'add' | 'remove' | 'replace'
		replaceClass?: string
		removeAfterDone?: boolean
		callback?: () => any
	}): AnimateCssElement {
		if (!this.isPlatformBrowser) return
		const prefix = `animate__`
		const elem: AnimateCssElement = document.querySelector(elementQuery) || null
		if (!elem) {
			console.error('Element is not defined!')
			return null
		}
		const foundElement = elem
		if (type === 'add') {
			foundElement.classList.add(
				...(foundElement.classList.contains(`${prefix}animated`)
					? [`${prefix}${animation}`]
					: [`${prefix}animated`, `${prefix}${animation}`])
			)
		}
		if (type === 'remove') foundElement.classList.remove(`${prefix}${animation}`)
		if (type === 'replace') {
			if (!replaceClass) {
				console.error('Must have replace class')
				return null
			}
			foundElement.classList.remove(`${prefix}${animation}`)
			foundElement.classList.add(`${prefix}${replaceClass}`)
		}
		const handleAnimationEnd = (event: Event) => {
			if (callback && typeof callback === 'function') {
				callback()
			}
			setTimeout(() => {
				elem.classList.remove(`${prefix}animated`, `${prefix}${animation}`)
			}, 100)
		}
		if (removeAfterDone) {
			elem.addEventListener('animationend', handleAnimationEnd, { once: true })
		}

		return elem
	}

	public constructAnimation(animation: {
		type: AnimationType
		repeat?: AnimationRepeatType
		speed?: AnimationSpeedType
	}) {
		const prefix = `animate__`
		return [
			`${prefix}animated`,
			`${prefix}${animation.repeat || 'repeat-1'}`,
			`${prefix}${animation.speed || 'fast'}`,
			`${prefix}${animation.type}`
		]
	}

	public getHtmlContent(template: string) {
		return this.callFunction('getHtmlContent', template)
	}

	public getKeys<T = any>(object: object): T {
		return this.callFunction('getKeys', object)
	}
}
