import { HttpParams } from '@angular/common/http'

export interface VidoleResponse<T> {
	results: T
	total?: number
}

export interface APIQuery<T> {
	paginatedField?: string
	sortAscending?: string
	include?: string | string[]
	keys?: string | string[]
	skip?: number
	limit?: number
	count?: boolean
	populate?: any
	aggregate?: any
	query?: Partial<Record<keyof T, any>>
}

export function toVidoleApiQueryString<T>(query: APIQuery<T> = {}) {
	let params = new HttpParams()
	if (query.populate) {
		params = params.append(
			'populate',
			typeof query.populate === 'string' ? query.populate : JSON.stringify(query.populate)
		)
	}
	if (query.skip) {
		params = params.append('skip', query.skip.toString())
	}
	if (query.limit) {
		params = params.append('limit', query.limit.toString())
	}
	if (query.aggregate) {
		params = params.append('aggregate', JSON.stringify(query.aggregate))
	}
	if (query.paginatedField) {
		params = params.append('paginatedField', query.paginatedField)
	}
	if (query.sortAscending) {
		params = params.append('sortAscending', query.sortAscending)
	}
	if (query.count) {
		params = params.append('count', query.count.toString())
	}
	if (query.query) {
		params = params.append('query', JSON.stringify(query.query))
	}
	return params.toString()
}
