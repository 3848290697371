import { environment } from '../../../environments/environment'
import { Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { NavigationStart, Router } from '@angular/router'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { filter } from 'rxjs/operators'

@UntilDestroy()
@Component({
	selector: 'app-popup-authentication',
	templateUrl: './popup-authentication.component.html',
	styleUrls: ['./popup-authentication.component.scss']
})
export class PopupAuthenticationComponent implements OnInit {
	env = environment
	constructor(
		private dialogRef: MatDialogRef<PopupAuthenticationComponent>,
		private router: Router
	) {}

	ngOnInit(): void {
		this.router.events
			.pipe(
				untilDestroyed(this),
				filter(event => event instanceof NavigationStart)
			)
			.subscribe(() => {
				this.dialogRef.close()
			})
	}
}
