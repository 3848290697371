/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { FunctionService } from '@/app/services/function.service'
import { Injectable } from '@angular/core'

class LocalStorage implements Storage {
	[name: string]: any
	readonly length: number
	clear(): void {}
	getItem(key: string): string | null {
		return undefined
	}
	key(index: number): string | null {
		return undefined
	}
	removeItem(key: string): void {}
	setItem(key: string, value: string): void {}
}

@Injectable({
	providedIn: 'root'
})
export class LocalStorageService implements Storage {
	[name: string]: any
	length: number
	private storage: Storage

	constructor(private functionService: FunctionService) {
		this.storage = new LocalStorage()

		if (this.functionService.isPlatformBrowser) {
			this.storage = localStorage
		}
	}
	clear(): void {
		this.storage.clear()
	}
	getItem(key: string): string | null {
		return this.storage.getItem(key)
	}
	key(index: number): string | null {
		return this.storage.key(index)
	}
	removeItem(key: string): void {
		return this.storage.removeItem(key)
	}
	setItem(key: string, value: string): void {
		return this.storage.setItem(key, value)
	}
}
