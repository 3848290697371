import { APP_STORAGE } from '@/shared/storage/storage.inject'
import { Overlay } from '@angular/cdk/overlay'
import {
	Component,
	HostListener,
	Inject,
	OnInit,
	ViewChild,
	WritableSignal,
	signal
} from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatIconRegistry } from '@angular/material/icon'
import { MatSidenav } from '@angular/material/sidenav'
import { DomSanitizer } from '@angular/platform-browser'
import { NavigationEnd, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { filter, take } from 'rxjs/operators'
import { BaseComponent } from '../components/base.component'
import { ContactInfoModalComponent } from '../components/contact-info-modal/contact-info-modal.component'
import {
	CookiePopupModalComponent,
	CookiePopupModalData
} from '../components/cookie-popup-modal/cookie-popup-modal.component'
import { LANGUAGE_OPTIONS, STORAGE_CONSTANTS } from '../constants/constants'
import { ILanguage } from '../models/auth.model'
import { IUser } from '../models/user.model'
import { CartService } from '../services'
import { FunctionService } from '../services/function.service'
import { UsersService } from '../services/user.service'
import { IMenuItem, menuItems } from './constants'
import { OwlOptions } from 'ngx-owl-carousel-o'

@Component({
	selector: 'app-layout',
	templateUrl: './layout.component.html',
	styleUrls: ['./layout.component.scss']
})
export class LayoutComponent extends BaseComponent implements OnInit {
	matRippleColor = 'rgb(240,188,104,0.3)'
	currentUser: IUser
	cookiesAccepted: boolean
	cartItems = 0
	isMobileBrowser: WritableSignal<boolean> = signal(false)
	currentLangId: string
	languages = LANGUAGE_OPTIONS
	isHomePage: boolean
	menuItems = menuItems
	footerMenuItems: IMenuItem[] = [
		{
			title: 'CGV',
			link: '/legal/sale-legal'
		},
		{
			title: 'legalNotice',
			link: '/legal/mention-legal'
		},
		{
			title: 'confidentiality',
			link: '/legal/privacy'
		}
	]
	customOptions: OwlOptions = {
		loop: true,
		fluidSpeed: true,
		autoplayTimeout: 10000,
		lazyLoad: true,
		autoplayHoverPause: true,
		autoplay: true,
		autoHeight: false,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: false,
		dots: false,
		navSpeed: 700,
		responsive: {
			0: {
				items: 1
			}
		},
		nav: false
	}

	slides: Array<{ message: string }> = [
		{
			message: `Le Tome 1 fait peau neuve. Découvrez la nouvelle couverture.`
		},
		{
			message: `Livraison gratuite en France à partir de 2 livres commandés`
		},
		{
			message: `10% automatique de réduction à partir de 3 livres dans votre panier.`
		}
	]

	@ViewChild('sidenav') sidenav: MatSidenav
	@ViewChild('basket-sidenav') basketSidenav: MatSidenav

	isExpanded = false
	isBasketExpanded = false
	constructor(
		private iconRegistry: MatIconRegistry,
		private sanitizer: DomSanitizer,
		private dialog: MatDialog,
		private userService: UsersService,
		@Inject(APP_STORAGE) private appStorage: Storage,
		private matDialog: MatDialog,
		private overlay: Overlay,
		private cartService: CartService,
		private router: Router,
		private functionService: FunctionService,
		private translate: TranslateService
	) {
		super()
		this.iconRegistry.addSvgIcon(
			'shopping-cart',
			this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/shopping-cart.svg')
		)
		this.iconRegistry.addSvgIcon(
			'user',
			this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/user.svg')
		)
		this.iconRegistry.addSvgIcon(
			'mail',
			this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/mail.svg')
		)
		this.iconRegistry.addSvgIcon(
			'story',
			this.sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/story.svg')
		)

		this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe(() => this.sidenav?.close())
	}

	ngOnInit(): void {
		this.isMobileBrowser.set(this.functionService.isMobileBrowser)
		this.setCartLength()

		this.userService.currentUser$.subscribe((user: IUser) => {
			this.currentUser = user
		})
		this.cartService.update$.subscribe(() => {
			this.setCartLength()
		})
		this.checkCookie()
		this.forceToFr()

		this.isHomePage = this.router.url === '/'
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.isHomePage = event.url === '/'
			}
		})
	}
	toggleSidenav() {
		this.isExpanded = !this.isExpanded
		if (this.isExpanded) {
			this.isBasketExpanded = false
		}
	}
	toggleBasketSidenav() {
		console.log('fired')
		this.isBasketExpanded = !this.isBasketExpanded

		if (this.isBasketExpanded) {
			this.isExpanded = false
		}
	}

	// @HostListener('window:scroll', ['$event'])
	// onWindowScroll(e: any) {
	// 	const scrollLimit = 200
	// 	const sticky =
	// 		document.documentElement.scrollHeight - window.outerHeight > scrollLimit

	// 	const navbar = document.getElementById('navbar')
	// 	if (window.scrollY > scrollLimit && sticky) {
	// 		navbar.classList.add('sticky')
	// 		return
	// 	}

	// 	if (window.scrollY < 5) {
	// 		navbar.classList.remove('sticky')
	// 	}
	// }

	forceToFr() {
		if (
			this.appStorage.getItem('language') !== 'fr' ||
			!this.appStorage.getItem('language')
		) {
			this.currentLangId = this.languages.FRENCH.id
			this.translate.use(this.languages.FRENCH.id)
		}
	}

	checkCookie() {
		this.cookiesAccepted = !!this.appStorage.getItem(STORAGE_CONSTANTS.COOKIES_ACCEPTED)
		if (this.cookiesAccepted) return
		this.openCookieModal()
	}

	openCookieModal() {
		this.matDialog
			.open<CookiePopupModalComponent, CookiePopupModalData>(CookiePopupModalComponent, {
				hasBackdrop: true,
				backdropClass: 'vidole-backdrop-deep',
				data: {
					cookiesAccepted: this.cookiesAccepted
				},
				position: {
					left: '0',
					top: '63vh'
				},
				maxWidth: '650px',
				maxHeight: '300px',
				scrollStrategy: this.overlay.scrollStrategies.noop()
			})
			.afterClosed()
			.pipe(take(1))
			.subscribe()
	}

	navigateToCart() {
		this.router.navigate(['/cart'])
	}

	onSelectLanguage(language: ILanguage) {
		this.currentLangId = language.id
		this.translate.use(language.id)
	}

	openContentPreviewDialog(): void {
		const modal: any = ContactInfoModalComponent
		const modalOptions: object = {
			backdropClass: 'vidole-backdrop',
			hasBackdrop: true,
			autoFocus: false
		}
		this.dialog.open(modal, modalOptions)
	}

	private setCartLength() {
		this.cartItems = this.cartService.getSize()
	}
}
