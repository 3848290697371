import { NgxSpinnerService } from 'ngx-spinner'
import { Observable, Subject } from 'rxjs'
import { debounceTime, finalize, take, takeUntil } from 'rxjs/operators'

export function subscribeOncePipe(param?: {
	spinner?: NgxSpinnerService
	callback?: () => void
}) {
	return function <T>(source: Observable<T>) {
		const { spinner, callback } = param || {}
		return source.pipe(
			take(1),
			finalize(() => {
				if (callback) callback()
				if (spinner) spinner.hide()
			})
		)
	}
}

export function subscribeUntilPipe(
	subject: Subject<unknown>,
	param?: { callback?: () => void; debounceTime?: number }
) {
	return function <T>(source: Observable<T>) {
		const { callback } = param || {}
		const pipeFunc = [
			takeUntil(subject),
			finalize(() => {
				if (callback) callback()
			}),
			...(param && param.debounceTime ? [debounceTime(param.debounceTime || 0)] : [])
		] as unknown
		// eslint-disable-next-line prefer-spread
		return source.pipe.apply(source, pipeFunc)
	}
}
