import { environment } from '../../environments/environment'
import { subscribeOncePipe } from '@/pipes/subscriptionPipe'
import { Injectable } from '@angular/core'
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { USER_CHARACTER_PROPERTY_KEY } from '../constants/character-style.constant'
import { IBookCover, IBookCoverProperties } from '../models/book-cover.model'
import { ICharacterImage } from '../models/character-image.model'
import { ICharacter } from '../models/character.model'
import { ApiService } from './api.service'
import { CharacterService } from './character.service'

@Injectable({
	providedIn: 'root'
})
export class BookCoverService {
	private endPoint = `${environment.endPointV1}/book-cover`

	public _allBookCover: BehaviorSubject<IBookCoverProperties[]> = new BehaviorSubject(
		null
	)
	public _mappedBookCover: BehaviorSubject<IBookCover[]> = new BehaviorSubject(null)

	constructor(
		private apiService: ApiService,
		private characterService: CharacterService
	) {
		this.initBookCover()
	}

	public get bookCover() {
		return this._mappedBookCover.asObservable()
	}

	public get allBookCover() {
		return this._allBookCover
	}

	public get bookCoverInstance() {
		return this._mappedBookCover.getValue()
	}

	private initBookCover() {
		combineLatest([
			this.getAllBookCover().pipe(subscribeOncePipe()), // only once
			this.characterService.character.asObservable().pipe(debounceTime(50))
		]).subscribe(([bookCovers, characterData]) => {
			if (!bookCovers || !bookCovers.length) return
			this._allBookCover.next(bookCovers)
			if (characterData) {
				this.mapBookCoverPreview(characterData)
			} else {
				console.error('missing chracter data')
			}
		})
	}

	public getAllBookCover() {
		return of([])
	}

	public getBookCoverCharacter(
		currentCharacter: /* display character */ ICharacter,
		characterImages: ICharacterImage[]
	): ICharacter {
		return Object.values(USER_CHARACTER_PROPERTY_KEY).reduce(
			(acc: any, property: string) => {
				const propertyName =
					currentCharacter[property] && currentCharacter[property]['name']
				const propertyType = `${property}hair-colored-cover-preview`
				if (!propertyName) return
				const characterProperty = characterImages.find(
					option =>
						option['name'] === propertyName &&
						option['gender'] === currentCharacter[property].gender &&
						option['type'] === propertyType
				)
				return { ...acc, [property]: characterProperty }
			},
			{}
		)
	}

	public mapBookCoverPreview(character: ICharacter) {
		// TODO: map language, will just use FR or now
		const bookCovers: IBookCover[] = this._allBookCover
			.getValue()
			.map((cover: IBookCoverProperties, index: number) => {
				const { id, characterImages, backgroundPreview } = cover
				// TODO: assuming each cover has its own character style
				const bookCoverCharacter = this.getBookCoverCharacter(character, characterImages)
				const newBookCover: IBookCover = {
					id,
					// content,
					previewCharacter: bookCoverCharacter,
					previewCoverBackground: backgroundPreview,
					selected: index === 0
				}
				return newBookCover
			})
		this._mappedBookCover.next(bookCovers)
	}
}
